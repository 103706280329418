import React, { useContext } from "react";
import { Link } from "react-router-dom";
import MainHeader from "./MainHeader";
import "./MainNavigation.css";
import { AuthContext } from "../../components/Context/auth-context";
import { CartContext } from "../Context/cart-context";

const MainNavigation = (props) => {
  //get data from context object
  const cartContext = useContext(CartContext);
  const auth = useContext(AuthContext);

  return (
    <MainHeader>
      <h1 className="app-header">
        {!auth.isLoggedIn && (
          <Link to="/">
            Tecnifer
          </Link>
        )}

        {auth.isLoggedIn && (
          /* @TODO */
          <Link to="/">
            <i className="fas fa-user-circle"></i> <span>{auth.userName}</span>
          </Link>
        )}
      </h1>

      <div className="app-header">
        {auth.isLoggedIn && auth.userRole === "admin" && (
          <Link to="/admin/managemenus">
            <i className="fas fa-user-cog"></i>
          </Link>
        )}

        <Link to="/cart">
          <img alt="cos-de-cumparaturi" src="/cart.svg" width="45" height="35" />
          <span class="spanImg">{cartContext.countItemsCart !== 0 ? cartContext.countItemsCart : ""}</span>
        </Link>
      </div>
    </MainHeader>
  );
};

export default MainNavigation;
