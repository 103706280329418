import React, { useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Button from '../../shared/components/FormElements/Button';
import Modal from '../../shared/components/UIElements/Modal';
import { AuthContext } from '../../shared/components/Context/auth-context';

const DeleteMenu = () => {
  const menuId = useParams().menuId;
  const history = useHistory();
  const auth = useContext(AuthContext);

  const menuDeleteHandler = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_DOMAIN}/v1/menus/edit/${menuId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: 'Bearer ' + auth.token
          }
        }
      );
      const responseData = await response.json();

      history.goBack();
      // Throw error
      if (!response.ok) {
        throw new Error(responseData.error);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <Modal
        show={true}
        header={'Stergere produs'}
        contentClass='menu-selected__modal-content'
        footerClass='menu-selected__modal-actions'
      >
        <h2 className='m'>Vrei sa stergi?</h2>
        <div className='display-menu-actions__modal'>
          <Button onClick={menuDeleteHandler}>DA</Button>
          <Button onClick={history.goBack}>NU</Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default DeleteMenu;
