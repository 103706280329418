import React from 'react';

import './Form.css';

const Form = props => {
  return (
    <form
      className={`form ${props.className}`}
      style={props.style}
      action={props.action}
      onSubmit={
        props.onSubmit ? props.onSubmit : event => event.preventDefault()
      }
    >
      {props.children}
    </form>
  );
};

export default Form;
