import React from 'react';

import './Table.css';

const Table = props => {
  return (
    <table className={`table ${props.className}`} style={props.style}>
      {props.children}
    </table>
  );
};

export default Table;
