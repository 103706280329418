import React, { useContext } from "react";

import { NavLink, Link } from "react-router-dom";

import { AuthContext } from "../../components/Context/auth-context";

import "./NavLinks.css";

const NavLinks = props => {
  const auth = useContext(AuthContext);

  return (
    <ul className="main-footer nav-links">
      <li>
        <NavLink to="/" exact>
          <i className="fas fa-home"></i>Home
        </NavLink>
      </li>

      {auth.isLoggedIn && (<li>
        <NavLink to="/orderhistory" exact>
          <i className="fas fa-user"></i>Comenzile mele
        </NavLink>
      </li>)}

      {/* {auth.isLoggedIn && (
        <li>
          <NavLink to="/orderhistory">
            <i className="fa fa-list-alt"></i>My Orders
          </NavLink>
        </li>
      )}

      {auth.isLoggedIn && (
        <li>
          <NavLink to="/reviews">
            <i className="fa fa-star"></i>My Reviews
          </NavLink>
        </li>
      )} */}

      {!auth.isLoggedIn && (
        <li>
          <NavLink to="/signin">
            <i className="fa fa-user"></i>Signin
          </NavLink>
        </li>
      )}

      {auth.isLoggedIn && (
        <li>
          <Link to="/signout" onClick={auth.logout}>
            <i className="fas fa-sign-out-alt"></i>Signout
          </Link>
        </li>
      )}

      <li>
        <NavLink to="/despre-noi">
          <i className="fa fa-info-circle"></i>Despre
        </NavLink>
      </li>
    </ul>
  );
};

export default NavLinks;
