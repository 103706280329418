import React from "react";

import { CSSTransition } from "react-transition-group";

import Card from "../../shared/components/UIElements/Card";

import "./CartItem.css";

const CartItem = (props) => {

  const changeItemQuantity = (menuId, operation) => {
    return props.changeItemQuantityFromChild(menuId, operation);
  };
  return (
    <React.Fragment>
      <li key={props.itemCart.menuId} className="order-item">
        <CSSTransition
          classNames={{
            appear: "slide-in-left-enter",

            appearActive: "slide-in-left-enter-active",
          }}
          timeout={1000}
          in
          appear
          unmountOnExit
        >
          <Card className="order-item__content">
            <div className="card-container">
              <div className="order-item__quantity_selector_container">
                <button
                  onClick={changeItemQuantity.bind(this, props.itemCart, "inc")}
                  type="button"
                  className="increment-quantity"
                >
                  <span>&#43;</span>
                </button>

                <input
                  readOnly={true}
                  type="text"
                  name="item_counter"
                  value={props.itemCart.quantity}
                  className="quantity"
                />

                <button
                  onClick={
                    props.itemCart.quantity > 1
                      ? changeItemQuantity.bind(this, props.itemCart, "dec")
                      : null
                  }
                  type="button"
                  className="decrement-quantity"
                >
                  <span>&#8722;</span>
                </button>
              </div>

              {/* <div className="order-item__image">
                <Avatar image={props.itemCart.image} alt="" />
              </div> */}

              <div className="order-item__name">
                {props.itemCart.name}

                <div className="order-item__price">
                  <span>
                    {props.itemCart.quantity}x ${props.itemCart.price} = $
                    {parseInt(props.itemCart.quantity) *
                      parseInt(props.itemCart.price)}
                  </span>
                </div>
              </div>

              <div className="order-item__delete">
                <i
                  className="fas fa-trash-alt"
                  onClick={() => {
                    props.removeFromCart(props.itemCart.menuId);
                  }}
                ></i>
              </div>
            </div>
          </Card>
        </CSSTransition>
      </li>
    </React.Fragment>
  );
};

export default CartItem;
