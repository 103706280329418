import React, { useState, useContext } from "react";

import { CSSTransition } from "react-transition-group";

import { AuthContext } from "../../shared/components/Context/auth-context";

import Table from "../../shared/components/UIElements/Table";

import Button from "../../shared/components/FormElements/Button";

import Modal from "../../shared/components/UIElements/Modal";

import Card from "../../shared/components/UIElements/Card";

import "./History.css";

const History = (props) => {
  const [ordersHistory, setOrdersHistory] = useState(props.itemsOrder);

  const [forDeleteOrder, setForDeleteOrder] = useState();

  const [showDeleteOrderHistory, setShowDeleteOrderHistory] = useState(false);

  const auth = useContext(AuthContext);
  
  const openDeleteOrderHistoryHandler = (orderId) => {
    setForDeleteOrder(orderId);

    setShowDeleteOrderHistory(true);
  };

  const confirmDeleteOrder = () => {
   
    const sendRequest = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_DOMAIN}/v1/order/${forDeleteOrder}/userid/${auth.userId}`,
          {
            method: "DELETE",
          }
        );

        const responseData = await response.json();

        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }
      } catch (err) {
        console.log(err);
      }
    };

    sendRequest();

    let currentItems = ordersHistory.filter((item, index) => {
      return item._id !== forDeleteOrder;
    });

    setOrdersHistory(currentItems);

    setShowDeleteOrderHistory(false);
  };

  const closeDeleteOrderHistoryHandler = () => setShowDeleteOrderHistory(false);

  /*  const formatDate = date => {console.log('date=', date);

    let monthNames = [

      'January',

      'February',

      'March',

      'April',

      'May',

      'June',

      'July',

      'August',

      'September',

      'October',

      'November',

      'December'

    ];

    let day = date.getDate();

    let monthIndex = date.getMonth();

    let year = date.getFullYear();

    return day + ' ' + monthNames[monthIndex] + ' ' + year;
  }; */

  return (
    <React.Fragment>
      <Modal
        show={showDeleteOrderHistory}
        onCancel={closeDeleteOrderHistoryHandler}
        header={"Stergere"}
        contentClass="menu-selected__modal-content"
        footerClass="menu-selected__modal-actions"
      >
        <h3 className="m center">
          Sigur vrei să ștergi această comandă?
        </h3>

        <div className="center display-menu-actions__modal">
          <Button onClick={confirmDeleteOrder}>Da</Button>
          <Button onClick={closeDeleteOrderHistoryHandler}>Nu</Button>
        </div>
      </Modal>

      <CSSTransition
        classNames={{
          appear: "slide-in-left-enter",

          appearActive: "slide-in-left-enter-active",
        }}
        timeout={1000}
        in
        appear
        unmountOnExit
      >
        {ordersHistory && ordersHistory.length > 0 ? (
          <div className="history-container">
            {auth.userRole !== "admin" && <h2 className="m">ISTORIC COMENZI</h2>}
            {auth.userRole === "admin" && <h2 className="m">COMENZI USERI</h2>}

            <Table>              
              <tbody>
                {ordersHistory.map((orderHistory, i) => {
                  return (
                    <tr>
                      <td>{orderHistory.createdAt}</td>

                      <td>{orderHistory.quantity}</td>

                      <td>{orderHistory.price}</td>

                      <td>{orderHistory.name}</td>

                      {auth.userRole === "admin" && <td>{orderHistory.userId.length === 24 ? props.userData.phone : orderHistory.userId}</td>}

                      <td className="btn-actions">
                        {auth.userRole !== "admin" && <Button
                          onClick={openDeleteOrderHistoryHandler.bind(
                            this,
                            orderHistory._id
                          )}
                          className="btn-delete"
                        >
                          STERGE
                        </Button>}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          <Card style={{ margin: "20px",textAlign:"center" }}>Nicio comandă în istoric</Card>
        )}
      </CSSTransition>
    </React.Fragment>
  );
};

export default History;
