import React from "react";

import { Link } from "react-router-dom";

import { CSSTransition } from "react-transition-group";

import Card from "../../shared/components/UIElements/Card";

import "./ReviewItem.css";

const ReviewItem = props => {
  return (
    <React.Fragment>
      <CSSTransition
        classNames={{
          appear: "slide-in-left-enter",

          appearActive: "slide-in-left-enter-active"
        }}
        timeout={1000}
        in
        appear
        unmountOnExit
      >
        <li className="review-item">
          <Card className="review-item__content">
            <div className="review-item__image">
              <Link
                className="link-menu__detail"
                to={`/${props.menuId}/menuDetail`}
              >
                <h4>{props.menuName.toUpperCase()}</h4>
              </Link>

              {props.reviewText}

              {", "}

              <span>Eu</span>
            </div>
      <div>{props.reviewDate}</div>

            <div className="review-item__info">
              {props.reviewRating === 5 ? (
                <div className="star-icon">
                  <i className="fas fa-star"></i>

                  <i className="fas fa-star"></i>

                  <i className="fas fa-star"></i>

                  <i className="fas fa-star"></i>

                  <i className="fas fa-star"></i>
                </div>
              ) : props.reviewRating === 4 ? (
                <div className="star-icon">
                  <i className="fas fa-star"></i>

                  <i className="fas fa-star"></i>

                  <i className="fas fa-star"></i>

                  <i className="fas fa-star"></i>
                </div>
              ) : props.reviewRating === 3 ? (
                <div className="star-icon">
                  <i className="fas fa-star"></i>

                  <i className="fas fa-star"></i>

                  <i className="fas fa-star"></i>
                </div>
              ) : props.reviewRating === 2 ? (
                <div className="star-icon">
                  <i className="fas fa-star"></i>

                  <i className="fas fa-star"></i>
                </div>
              ) : props.reviewRating === 1 ? (
                <span>
                  <i className="fas fa-star"></i>
                </span>
              ) : (
                <p></p>
              )}
            </div>
          </Card>
        </li>
      </CSSTransition>
    </React.Fragment>
  );
};

export default ReviewItem;
