import React, { useState, useEffect } from "react";

import { CSSTransition } from "react-transition-group";

import MenuItem from "./MenuItem";

import DocumentMeta from 'react-document-meta';

import "./MenusList.css";

const MenusList = props => {

  const [isVisibleSubCategs, setIsVisibleSubCategs] = useState(false);
  const [filteredItems, setFilteredItems] = useState(props.items);
  const [selectedSubcateg, setSelectedSubcateg] = useState();

  useEffect(() => {
    setFilteredItems(props.items);
  }, [props.items]);

  const handleSelectOnclick = () => {
    if (isVisibleSubCategs) {
      setIsVisibleSubCategs(false);
    } else {
      setIsVisibleSubCategs(true);
    }

  }

  const handleSubcategOnClick = (event) => {
    const filtered = props.items.filter((item) => {
      setIsVisibleSubCategs(false);
      setSelectedSubcateg(event.target.value);
      return item.subcategory[0] === event.target.value;
    });

    if (filtered.length > 0) {
      setFilteredItems(filtered);
    }

  }

  if (props.items.length === 0) {
    return (
      <h2 className="not-found">
        Niciun produs din categoria {props.menutype.toUpperCase()}.
      </h2>
    );
  }

  const meta = {
    title: `${props.menutype ? props.menutype.toUpperCase().replace(/-/g, ' ') + " | Tecnifer.ro" : "Tecnifer.ro"}`,
    description: `Alege ${props.menutype ? props.menutype.toUpperCase().replace(/-/g, ' ') : null} ieftin si de calitate de la Tecnifer`,
  };

  return (
    <DocumentMeta {...meta}>
      <React.Fragment>

        <div className="background content">
          <ul className="menu-list">
            <h1 className="header">{props.menutype ? props.menutype.toUpperCase().replace(/-/g, ' ') : null}</h1>

            <CSSTransition
              classNames={{
                appear: "slide-in-bottom",
                appearActive: "slide-in-bottom-active"
              }}
              timeout={1000}
              in
              appear
              unmountOnExit
            >
              <div>
                {props.categories && <div className="subcateg__container">
                  <div className="subcateg__select" onClick={handleSelectOnclick}><span>{selectedSubcateg ? selectedSubcateg : "Filtreaza"}</span><span className="subcateg__span"><i className="fas fa-caret-down"></i></span></div>
                  <ul className={`subcateg__ul ${isVisibleSubCategs ? "" : "hidden"}`} >
                    {props.categories &&
                      props.categories.subcategs.map((subcateg) => {
                        return <li key={'listItem ' + subcateg} className="subcateg__li"><input id={subcateg} name="subcatInput" className="subcateg__input" value={subcateg} type="radio" onClick={handleSubcategOnClick} /><label htmlFor={subcateg}>{subcateg}</label></li>;
                      })
                    }
                  </ul>
                </div>}
                {filteredItems.map(menus => (
                  <MenuItem
                    key={menus._id}
                    id={menus._id}
                    category={menus.category}
                    subcategory={menus.subcategory}
                    image={menus.imageMain}
                    slug={menus.slug}
                    name={menus.name}
                    price={menus.price}
                    description={menus.description}
                    available={menus.available}
                  />
                ))}
              </div>
            </CSSTransition>
          </ul>
        </div>
      </React.Fragment>
    </DocumentMeta>
  );
};

export default MenusList;
