import React, { useEffect, useState, useContext } from "react";

import ErrorModal from "../../shared/components/UIElements/ErrorModal";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import MenuList from "../components/MenuList";

import { AuthContext } from "../../shared/components/Context/auth-context";

const ManageMenus = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState();
  const [loadedMenus, setLoadedMenus] = useState();

  const auth = useContext(AuthContext);

  useEffect(() => {
    setIsLoading(true);

    const sendRequest = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/v1/menus`, {
          headers: {
            Authorization: "Bearer " + auth.token
          }
        });

        const responseData = await response.json();

        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }

        setLoadedMenus(responseData.data);
      } catch (err) {
        setError(err.message);
      }

      setIsLoading(false);
    };

    sendRequest();
  }, [auth.token]);

  const errorHandler = () => {
    setError(null);
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={errorHandler} />

      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}

      {!isLoading && loadedMenus && <MenuList items={loadedMenus} />}
    </React.Fragment>
  );
};

export default ManageMenus;
