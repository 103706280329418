import React from 'react';

import ReviewItem from './ReviewItem';
import './ReviewList.css';

const ReviewList = props => {
  if (props.items.length === 0) {
    return (
      <div className='center'>
        <h2>No users found.</h2>
      </div>
    );
  }

  return (
    <ul className='review-list'>
      {props.items.map(review => (
        <ReviewItem
          key={review._id}
          userId={review.user}          
          menuId={review.menu._id}
          menuName={review.menu.name}
          reviewText={review.text}
          reviewRating={review.rating}
          reviewDate={review.createdAt}
        />
      ))}
    </ul>
  );
};

export default ReviewList;
