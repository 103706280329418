import React, { useEffect, useState } from "react";

import ErrorModal from "../../shared/components/UIElements/ErrorModal";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import MenusList from "../components/MenusList";

const Menu = (props) => {

  const category = props.category;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubCategs, setIsLoadingSubCategs] = useState(false);

  const [error, setError] = useState();

  const [loadedMenus, setLoadedMenus] = useState();
  const [loadedSubCategs, setLoadedSubCategs] = useState();


  useEffect(() => {

    setIsLoading(true);

    const sendRequest = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_DOMAIN}/v1/menus/category/${category}`
        );

        const responseData = await response.json();

        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }
        setLoadedMenus(responseData.data);

      } catch (err) {
        setError(err.message);
      }

      setIsLoading(false);
    };

    sendRequest();
  }, [category]);

  useEffect(() => {

    setIsLoadingSubCategs(true);

    const sendRequest = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_DOMAIN}/v1/subcategories/${category}`
        );

        const responseData = await response.json();

        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }
        setLoadedSubCategs(responseData.data);
      } catch (err) {
        setError(err.message);
      }

      setIsLoadingSubCategs(false);
    };

    sendRequest();
  }, [category]);


  const errorHandler = () => {
    setError(null);
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={errorHandler} />

      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}

      {!isLoadingSubCategs && !isLoading && loadedMenus && loadedSubCategs && (
        <MenusList items={loadedMenus} menutype={category} categories={loadedSubCategs} />
      )}
    </React.Fragment>
  );
};

export default Menu;
