import React from 'react';
import PropTypes from 'prop-types';

import './ImagePreview.css';

export const ImagePreview = ({ dataUri, isFullscreen }) => {
  let classNameFullscreen = isFullscreen ? 'demo-image-preview-fullscreen' : '';

  return (        
    (dataUri.length === 1) ? <div className={'demo-image-preview ' + classNameFullscreen}><img src={dataUri[0]} /></div> :
    (dataUri.length === 2) ? <div className={'demo-image-preview ' + classNameFullscreen}><img src={dataUri[0]} /><img src={dataUri[1]} /></div> : 
    (dataUri.length === 3) ? <div className={'demo-image-preview ' + classNameFullscreen}><img src={dataUri[0]} /><img src={dataUri[1]} /><img src={dataUri[2]} /></div> : ''   
  );
};

ImagePreview.propTypes = {
  dataUri: PropTypes.array,
  isFullscreen: PropTypes.bool
};

export default ImagePreview;