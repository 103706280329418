import React from "react";
import { Link } from "react-router-dom";
import Card from "../../shared/components/UIElements/Card";
import Item from "../../shared/components/UIElements/Item";
import "./MenuItem.css";

const MenuItem = props => {
  let smallImgPath = props.image.replace("images", "resimages");

  return (
    <React.Fragment>
      <Link to={`/${props.category}/${props.subcategory}/${props.slug}`}>
        {props.category !== "buffet" && (
          <Card className="menu-item__content">
            <div className="menu-item__image">
              <img
                src={`https://tecnifer.ro/backend/${smallImgPath}`}
                alt={props.slug}
              />
            </div>

            <div className="menu-item__text">
              <div className="name">{props.name}</div>

              <hr style={{ color: "white", height: 1 }} />

              <div className="desc">
                <p>
                  {props.description.length > 42
                    ? props.description.substring(0, 42) + "..."
                    : props.description}
                </p>
              </div>

              <div className="price">{props.price} Lei</div>
            </div>
          </Card>
        )}
      </Link>
    </React.Fragment>
  );
};

// Show only 5 or 10 paragraphs in description (truncate)

export default MenuItem;
