import { useState, useCallback, useEffect } from "react";

let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(false);

  const [tokenExpirationDate, setTokenExpirationDate] = useState();

  const [userId, setUserId] = useState(false);

  const [userRole, setUserRole] = useState(false);

  const [userName, setUserName] = useState(false);

  const [userIDDD, setUserIDDD] = useState(false);

  const setUserIDD = useCallback(
    (uidd) => {
      setUserIDDD(uidd);     

      localStorage.setItem(
        "userIDD",

        JSON.stringify({
          userIDD: uidd,         
        })
      );
    },
    []
  );

  const login = useCallback(
    (uid, token, userRole, userName, expirationDate) => {
      
      setToken(token);

      setUserId(uid);

      setUserRole(userRole);

      setUserName(userName);

      const tokenExpirationDate =
        expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 8);

      setTokenExpirationDate(tokenExpirationDate);

      localStorage.setItem(
        "userData",

        JSON.stringify({
          userId: uid,

          token: token,

          userRole: userRole,

          userName: userName,

          expiration: tokenExpirationDate.toISOString(),
        })
      );
    },

    []
  );

  const logout = useCallback(() => {
    setToken(null);

    setTokenExpirationDate(null);

    setUserId(null);

    setUserRole(null);

    setUserName(null);

    localStorage.removeItem('userData');
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();

      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    const storedUIDD = JSON.parse(localStorage.getItem("userIDD"));

    if(storedUIDD !== null){
      setUserIDD(storedUIDD.userIDD);
    }
    

    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.userId,

        storedData.token,

        storedData.userRole,

        storedData.userName,

        new Date(storedData.expiration)
      );
    }
  }, [login,setUserIDD]);

  return { token, login, logout, userId, userRole, userName, setUserIDD, userIDDD };
};
