import React, { useEffect, useState, useContext } from "react";

import { useParams } from "react-router-dom";

import ErrorModal from "../../shared/components/UIElements/ErrorModal";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import { AuthContext } from "../../shared/components/Context/auth-context";

import MenuSelected from "../components/MenuSelected";

const MenuDetail = props => {
  const slugProd = useParams().slugProd;

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState();

  const [loadedMenus, setLoadedMenus] = useState();

  const [loadedMenus1, setLoadedMenus1] = useState();

  const auth = useContext(AuthContext);

  const userId = auth.userId;

  useEffect(() => {
    setIsLoading(true);

    const sendRequest = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_DOMAIN}/v1/menus/${slugProd}`
        );

        const responseData = await response.json();

        if (auth.isLoggedIn) {
          const response1 = await fetch(
            `${process.env.REACT_APP_API_DOMAIN}/v1/menus/${slugProd}/${userId}/review`
          );

          const responseData1 = await response1.json();
          setLoadedMenus1(responseData1.data[0]);

          if (!response1.ok) {
            throw new Error(responseData1.error);
          }
        }

        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }
        setLoadedMenus(responseData.data);

      } catch (err) {
        setError(err.message);
      }

      setIsLoading(false);
    };

    sendRequest();
  }, [slugProd, userId, auth]);

  const errorHandler = () => {
    setError(null);
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={errorHandler} />

      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}

      {!isLoading && loadedMenus && (
        <MenuSelected
          getQuantityCart={props.getQuantityCart}
          item={loadedMenus}
          item1={loadedMenus1}
        />
      )}
    </React.Fragment>
  );
};

export default MenuDetail;
