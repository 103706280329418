import React from 'react';

import NavLinks from './NavLinks';

import './MainFooter.css';

const MainFooter = props => {
  return (
    <nav className='main-footer'>
      <NavLinks />
    </nav>
  );
};

export default MainFooter;
