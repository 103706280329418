import React from "react";

import ReactDOM from "react-dom";

import "./index.css";

import App from "./App";

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-MCFFRPV'
}

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById("root"));
