import { createContext } from 'react';

export const AuthContext = createContext({

  isLoggedIn: false,

  token: null,

  userId: null,

  userRole: null,

  userIDD: null,

  setUserIDD: () => {},

  login: () => {},

  logout: () => {}

});

