import React from "react";

import "./MenuItem.css";

const MenuItem = props => {
  let date = new Date(props.createdAt);

  let year = date.getFullYear();

  let month = date.getMonth() + 1;

  let dt = date.getDate();

  if (dt < 10) {
    dt = "0" + dt;
  }

  if (month < 10) {
    month = "0" + month;
  }

  let formatDate = year + "-" + month + "-" + dt;

  return (
    <React.Fragment>
      <td>{formatDate}</td>

      <td>{props.name}</td>

      <td>{props.category}</td>

      <td>{props.subcategory}</td>

      <td>{props.price}</td>

      <td>{props.priceOld}</td>

      <td>{props.color}</td>

      <td>{props.dimensions}</td>

      <td>{props.brand}</td>

      <td>{props.material}</td>

      <td>{props.quantity}</td>

      <td>{props.description}</td>
      
    </React.Fragment>
  );
};

export default MenuItem;
