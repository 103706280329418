import React, { useCallback, useReducer, useState } from "react";

import { Link, useHistory } from "react-router-dom";

import { CSSTransition } from "react-transition-group";

import Form from "../../shared/components/UIElements/Form";

import Button from "../../shared/components/FormElements/Button";

import Input from "../../shared/components/FormElements/Input";

import Modal from "../../shared/components/UIElements/Modal";

import ErrorModal from "../../shared/components/UIElements/ErrorModal";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH,
  VALIDATOR_EMAIL,
} from "../../shared/util/validators";

import "./UserSignup.css";

const formReducer = (state, action) => {
  switch (action.type) {
    case "INPUT_CHANGE":
      let formIsValid = true;

      for (const inputId in state.inputs) {
        if (inputId === action.inputId) {
          formIsValid = formIsValid && action.isValid;
        } else {
          formIsValid = formIsValid && state.inputs[inputId].isValid;
        }
      }

      return {
        ...state,

        inputs: {
          ...state.inputs,

          [action.inputId]: { value: action.value, isValid: action.isValid },
        },

        isValid: formIsValid,
      };

    default:
      return state;
  }
};

const UserSignup = () => {
  const [formState, dispatch] = useReducer(formReducer, {
    inputs: {
      name: {
        value: "",

        isValid: false,
      },

      email: {
        value: "",

        isValid: false,
      },

      phone: {
        value: "",

        isValid: false,
      },

      password: {
        value: "",

        isValid: false,
      },

      confirmPassword: {
        value: "",

        isValid: false,
      },
    },

    isValid: false,
  });

  const inputHandler = useCallback(
    (id, value, isValid) => {
      dispatch({
        type: "INPUT_CHANGE",

        value: value,

        isValid: isValid,

        inputId: id,
      });
    },

    [dispatch]
  );

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState();

  const history = useHistory();

  const UserSignupHandler = async (event) => {
    event.preventDefault();

    if (
      formState.inputs.password.value === formState.inputs.confirmPassword.value
    ) {
      try {
        setIsLoading(true);

        const response = await fetch(
          `${process.env.REACT_APP_API_DOMAIN}/v1/auth/register`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },

            body: JSON.stringify({
              name: formState.inputs.name.value,

              email: formState.inputs.email.value,

              phone: formState.inputs.phone.value,

              password: formState.inputs.password.value,
            }),
          }
        );

        const responseData = await response.json();

        // Throw error

        if (!response.ok) {
          throw new Error(responseData.error);
        }

        setIsLoading(false);

        openShowSuccesHandler(true);
      } catch (err) {
        if (err.message === "Un camp este duplicat") {
          setError("Emailul este duplicat");
        } else {
          setError("Something went wrong please try again");
        }

        setIsLoading(false);
      }
    } else {
      // alert('Password dont match');

      setError("Password and confirm password do not match");

      formState.inputs.password.value = "";

      formState.inputs.confirmPassword.value = "";
    }
  };

  const errorHandler = () => {
    setError(null);
  };

  // Show modal form const

  const [showSuccess, setShowSuccess] = useState(false);

  const openShowSuccesHandler = () => setShowSuccess(true);

  // const closeShowSuccessHandler = () => setShowSuccess(false);

  return (
    <React.Fragment>
      <Modal
        show={showSuccess}
        // onCancel={closeShowSuccessHandler}

        header={"Success"}
        contentClass="menu-selected__modal-content"
        footerClass="menu-selected__modal-actions"
      >
        <p>
          Te-ai înregistrat cu succes, te redirectăm către Logare
        </p>

        <footer>
          <Button className="btn" onClick={history.goBack}>
            Ok
          </Button>
        </footer>
      </Modal>

      <ErrorModal error={error} onClear={errorHandler} />

      <CSSTransition
        classNames={{
          appear: "slide-in-left-enter",

          appearActive: "slide-in-left-enter-active",
        }}
        timeout={1000}
        in
        appear
        unmountOnExit
      >
        <div className="content">
          {isLoading && <LoadingSpinner asOverlay />}

          <section className="container user-signup-container">
            <h1 className="large text-primary">Înregistrare</h1>

            <p className="lead">
              <i className="fas fa-user"></i>Crează un cont
            </p>

            <Form className="form" onSubmit={UserSignupHandler}>
              <div className="form-group">
                <Input
                  id="name"
                  element="input"
                  type="text"
                  placeholder="NUME COMPLET"
                  validators={[VALIDATOR_REQUIRE()]}
                  errorText="Please enter a full name"
                  onInput={inputHandler}
                />
              </div>

              <div className="form-group">
                <Input
                  id="email"
                  element="input"
                  type="email"
                  placeholder="EMAIL"
                  validators={[VALIDATOR_EMAIL()]}
                  errorText="Please enter a valid email"
                  onInput={inputHandler}
                />
              </div>

              <Input
                id="phone"
                element="input"
                type="text"
                placeholder="TELEFON" 
                validators={[]}               
                errorText="Please enter a phone number"
                onInput={inputHandler}
              />

              <div className="form-group">
                <Input
                  id="password"
                  element="input"
                  type="password"
                  placeholder="PAROLA"
                  validators={[VALIDATOR_MINLENGTH(8)]}
                  errorText="Minimum 8 characters"
                  onInput={inputHandler}
                  value={formState.inputs.password.value}
                />
              </div>

              <div className="form-group">
                <Input
                  id="confirmPassword"
                  element="input"
                  type="password"
                  placeholder="CONFIRMA PAROLA"
                  validators={[VALIDATOR_MINLENGTH(8)]}
                  errorText="MInimum 8 characters"
                  onInput={inputHandler}
                  value={formState.inputs.confirmPassword.value}
                />
              </div>

              <Button
                id="btnSubmit"
                type="submit"
                disabled={!formState.isValid}
              >
                SUBMIT
              </Button>
            </Form>

            <p className="my-1">
              Ai deja un cont? <Link to={"/signin"}>Logare</Link>
            </p>
          </section>
        </div>
      </CSSTransition>
    </React.Fragment>
  );
};

export default UserSignup;
