import React from "react";

import { Link } from "react-router-dom";

import { CSSTransition } from "react-transition-group";

import Card from "../../shared/components/UIElements/Card";

import "./ProductReviewItem.css";

const ReviewItem = props => {
  return (
    <React.Fragment>
      <CSSTransition
        classNames={{
          appear: "slide-in-left-enter",

          appearActive: "slide-in-left-enter-active"
        }}
        timeout={1000}
        in
        appear
        unmountOnExit
      >
        <li className="product-review-item_content">
          {/* user image,name and date post review */}
          <div className="product-review-item_top">
            <div className="user-data fifty">
              <div className="user-image">
                <img className="image" alt="" />
              </div>
              <div className="user-name fifty">
                {props.userName.slice(0, 6)}
              </div>
            </div>
            {/* date */}
            <div className="date-review fifty">
              <div className="">{props.reviewDate.slice(0, 10)}</div>
            </div>
          </div>

          {/* stars */}
          <div className="product-review-item_middle">
            <div className="rating-stars">
              {props.reviewRating === 5 ? (
                <div className="star-icon">
                  <i className="fas fa-star"></i>

                  <i className="fas fa-star"></i>

                  <i className="fas fa-star"></i>

                  <i className="fas fa-star"></i>

                  <i className="fas fa-star"></i>
                </div>
              ) : props.reviewRating === 4 ? (
                <div className="star-icon">
                  <i className="fas fa-star"></i>

                  <i className="fas fa-star"></i>

                  <i className="fas fa-star"></i>

                  <i className="fas fa-star"></i>
                </div>
              ) : props.reviewRating === 3 ? (
                <div className="star-icon">
                  <i className="fas fa-star"></i>

                  <i className="fas fa-star"></i>

                  <i className="fas fa-star"></i>
                </div>
              ) : props.reviewRating === 2 ? (
                <div className="star-icon">
                  <i className="fas fa-star"></i>

                  <i className="fas fa-star"></i>
                </div>
              ) : props.reviewRating === 1 ? (
                <span className="star-icon">
                  <i className="fas fa-star"></i>
                </span>
              ) : (
                          <p></p>
                        )}
            </div>
          </div>

          {/* review text */}
          <div className="product-review-item_bottom">{props.reviewText}</div>
        </li>
      </CSSTransition>
    </React.Fragment>
  );
};

export default ReviewItem;
