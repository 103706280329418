import React from "react";

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";

import Menus from "./menus/pages/Menus";

import Menu from "./menus/pages/Menu";

import MenuDetail from "./menus/pages/MenuDetail";

import ManageMenus from "./admin/Pages/ManageMenus";

import AddMenu from "./admin/components/AddMenu";

import DeleteMenu from "./admin/components/DeleteMenu";

import UpdateMenu from "./admin/Pages/UpdateMenu";

import About from "./about/pages/About";

import Cart from "./cart/pages/Cart";

import OrderHistory from "./orders/pages/OrderHistory";

import Signin from "./accounts/pages/Signin";

import Signup from "./accounts/pages/Signup";

import Forgot from "./accounts/pages/Forgot";

import Reset from "./accounts/pages/Reset";

import Reviews from "./reviews/pages/Reviews";

import MainNavigation from "./shared/components/Navigation/MainNavigation";

import MainFooter from "./shared/components/Navigation/MainFooter";

import { CartContext } from "./shared/components/Context/cart-context";

import { AuthContext } from "./shared/components/Context/auth-context";

import { useAuth } from "./shared/hooks/auth-hooks";

import { useCart } from "./shared/hooks/cart-hooks";


const App = () => {
  // Auth
  const { token, login, logout, userId, userRole, userName, setUserIDD, userIDDD } = useAuth();

  //Cart
  const {
    saveCountItemsCart,
    saveCartItems,
    countItemsCart,
    cartItems
  } = useCart();

  let routes;
  //let match = useRouteMatch();

  if (token) {
    routes = (
      <Switch>

        <Route path="/" exact>
          <Menus />
        </Route>

        <Route path="/admin/managemenus" exact>
          <ManageMenus />
        </Route>

        <Route path="/admin/managemenus/addproduct" exact>
          <AddMenu />
        </Route>

        <Route path="/admin/managemenus/:menuId" exact>
          <UpdateMenu />
        </Route>

        <Route path="/admin/managemenus/delete/:menuId" exact>
          <DeleteMenu />
        </Route>

        <Route path="/:category/:subcategory/:slugProd" exact>
          <MenuDetail />
        </Route>

        <Route path="/cart" exact>
          <Cart />
        </Route>

        <Route path="/orderhistory" exact>
          <OrderHistory />
        </Route>

        <Route path="/reviews" exact>
          <Reviews />
        </Route>

        <Route path="/despre-noi" exact>
          <About />
        </Route>

        <Route path="/accesorii-mobila">
          <Menu category="accesorii-mobila" />
        </Route>
        <Route path="/accesorii-tapiterie">
          <Menu category="accesorii-tapiterie" />
        </Route>
        <Route path="/ornamente-mobila-lemn">
          <Menu category="ornamente-mobila-lemn" />
        </Route>
        <Route path="/menghine-tamplar">
          <Menu category="menghine-tamplar" />
        </Route>
        <Route path="/adezivi-si-chituri-pentru-lemn">
          <Menu category="adezivi-si-chituri-pentru-lemn" />
        </Route>
        <Route path="/solutii-pentru-tratarea-lemnului">
          <Menu category="solutii-pentru-tratarea-lemnului" />
        </Route>
        <Route path="/cuie-capse-si-agrafe">
          <Menu category="cuie-capse-si-agrafe" />
        </Route>
        <Route path="/abrazive-pentru-lemn">
          <Menu category="abrazive-pentru-lemn" />
        </Route>
        <Route path="/pistoale-pneumatice-si-vopsitorie">
          <Menu category="pistoale-pneumatice-si-vopsitorie" />
        </Route>
        <Route path="/echipamente-de-protectie">
          <Menu category="echipamente-de-protectie" />
        </Route>

        <Redirect to="/" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/" exact>
          <Menus />
        </Route>

        <Route path="/menus/:menutype" exact>
          <Menu />
        </Route>

        <Route path="/reviews" exact>
          <Reviews />
        </Route>

        <Route path="/signin" exact>
          <Signin />
        </Route>

        <Route path="/signup" exact>
          <Signup />
        </Route>

        <Route path="/forgot" exact>
          <Forgot />
        </Route>

        <Route path="/cart" exact>
          <Cart />
        </Route>

        <Route path="/resetpassword/:resetToken" exact>
          <Reset />
        </Route>

        <Route path="/despre-noi" exact>
          <About />
        </Route>

        <Route path="/:category/:subcategory/:slugProd" exact>
          <MenuDetail />
        </Route>

        <Route path="/accesorii-mobila">
          <Menu category="accesorii-mobila" />
        </Route>
        <Route path="/accesorii-tapiterie">
          <Menu category="accesorii-tapiterie" />
        </Route>
        <Route path="/ornamente-mobila-lemn">
          <Menu category="ornamente-mobila-lemn" />
        </Route>
        <Route path="/menghine-tamplar">
          <Menu category="menghine-tamplar" />
        </Route>
        <Route path="/adezivi-si-chituri-pentru-lemn">
          <Menu category="adezivi-si-chituri-pentru-lemn" />
        </Route>
        <Route path="/solutii-pentru-tratarea-lemnului">
          <Menu category="solutii-pentru-tratarea-lemnului" />
        </Route>
        <Route path="/cuie-capse-si-agrafe">
          <Menu category="cuie-capse-si-agrafe" />
        </Route>
        <Route path="/abrazive-pentru-lemn">
          <Menu category="abrazive-pentru-lemn" />
        </Route>
        <Route path="/pistoale-pneumatice-si-vopsitorie">
          <Menu category="pistoale-pneumatice-si-vopsitorie" />
        </Route>
        <Route path="/echipamente-de-protectie">
          <Menu category="echipamente-de-protectie" />
        </Route>

        <Redirect to="/" />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,

        token: token,

        userId: userId,

        userRole: userRole,

        userName: userName,

        setUserIDD: setUserIDD,

        userIDD: userIDDD,

        login: login,

        logout: logout
      }}
    >
      <CartContext.Provider
        value={{
          countItemsCart: countItemsCart,

          cartItems: cartItems,

          saveCountItemsCart: saveCountItemsCart,

          saveCartItems: saveCartItems
        }}
      >
        <Router>
          <MainNavigation />

          <main>{routes}</main>

          <MainFooter />
        </Router>
      </CartContext.Provider>
    </AuthContext.Provider>
  );
};

export default App;
