import React, { useState, useCallback, useReducer } from "react";

import { Link, useHistory } from "react-router-dom";

import { CSSTransition } from "react-transition-group";

import Input from "../../shared/components/FormElements/Input";

import Form from "../../shared/components/UIElements/Form";

import Button from "../../shared/components/FormElements/Button";

import Modal from "../../shared/components/UIElements/Modal";

import ErrorModal from "../../shared/components/UIElements/ErrorModal";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import { VALIDATOR_EMAIL } from "../../shared/util/validators";

import "./UserForgot.css";

const formReducer = (state, action) => {
  switch (action.type) {
    case "INPUT_CHANGE":
      let formIsValid = true;

      for (const inputId in state.inputs) {
        if (inputId === action.inputId) {
          formIsValid = formIsValid && action.isValid;
        } else {
          formIsValid = formIsValid && state.inputs[inputId].isValid;
        }
      }

      return {
        ...state,

        inputs: {
          ...state.inputs,

          [action.inputId]: { value: action.value, isValid: action.isValid },
        },

        isValid: formIsValid,
      };

    default:
      return state;
  }
};

const UserForgot = () => {
  const [formState, dispatch] = useReducer(formReducer, {
    inputs: {
      email: {
        value: "",

        isValid: false,
      },
    },

    isValid: false,
  });

  const inputHandler = useCallback(
    (id, value, isValid) => {
      dispatch({
        type: "INPUT_CHANGE",

        value: value,

        isValid: isValid,

        inputId: id,
      });
    },

    [dispatch]
  );

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState();

  const history = useHistory();

  const forgotHandler = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    try {
      setIsLoading(true);

      const response = await fetch(
        `${process.env.REACT_APP_API_DOMAIN}/v1/auth/forgotpassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify({
            email: formState.inputs.email.value,
          }),
        }
      );

      const responseData = await response.json();

      // Throw error
      if (!response.ok) {
        throw new Error(responseData.error);
      }

      setIsLoading(false);

      openShowSuccesHandler(true);
    } catch (err) {
      setError(err.message || "Something went wrong please try again");

      setIsLoading(false);
    }
  };

  const errorHandler = () => {
    setError(null);
  };

  // Show modal form const

  const [showSuccess, setShowSuccess] = useState(false);

  const openShowSuccesHandler = () => setShowSuccess(true);

  return (
    <React.Fragment>
      <Modal
        show={showSuccess}
        // onCancel={closeShowSuccessHandler}

        header={"Success"}
        contentClass="menu-selected__modal-content"
        footerClass="menu-selected__modal-actions"
      >
        <p>
          Mail sent to your email, Check your email immediately for the
          instruction to reset your password
        </p>

        <footer>
          <Button className="btn" onClick={history.goBack}>
            Ok
          </Button>
        </footer>
      </Modal>

      <ErrorModal error={error} onClear={errorHandler} />

      <CSSTransition
        classNames={{
          appear: "slide-in-left-enter",

          appearActive: "slide-in-left-enter-active",
        }}
        timeout={1000}
        in
        appear
        unmountOnExit
      >
        <section className="container user-forgot-container">
          {isLoading && <LoadingSpinner asOverlay />}

          <h1 className="medium text-primary">RESETARE PAROLĂ</h1>

          <p className="lead">
            <i className="fas fa-user"></i>Introduceti email-ul
          </p>

          <Form className="form" onSubmit={forgotHandler}>
            <div className="form-group">
              <Input
                id="email"
                element="input"
                type="text"
                placeholder="Email"
                validators={[VALIDATOR_EMAIL()]}
                errorText="Please enter a valid email address"
                onInput={inputHandler}
              />
            </div>

            <Button id="btnSubmit" type="submit" disabled={!formState.isValid}>
              SUBMIT
            </Button>
          </Form>

          <p className="my-1">
            Nu ai cont? <Link to={"/signup"}>Înregistrare</Link>
          </p>
        </section>
      </CSSTransition>
    </React.Fragment>
  );
};

export default UserForgot;
