import React, { useEffect, useState, useContext } from "react";

import ErrorModal from "../../shared/components/UIElements/ErrorModal";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import ReviewList from "../components/ReviewList";

import { AuthContext } from "../../shared/components/Context/auth-context";

const Reviews = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState();

  const [isLoadingReviews, setIsLoadingReviews] = useState(false);

  const [loadedUserReviews, setLoadedUserReviews] = useState();

  const auth = useContext(AuthContext);

  useEffect(() => {
    setIsLoadingReviews(true);
    const sendRequest = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_DOMAIN}/v1/reviews/userId/`+auth.userId
        );
        const responseData = await response.json();
        
        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }

        setLoadedUserReviews(responseData.data);
      } catch (err) {
        setError(err.message);
      }

      setIsLoading(false);
    };

    sendRequest();
  }, [auth.userId]);

  const errorHandler = () => {
    setError(null);
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={errorHandler} />

      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}

      {!isLoading && loadedUserReviews && <ReviewList items={loadedUserReviews} />}
    </React.Fragment>
  );
};

export default Reviews;
