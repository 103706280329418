import React, { Fragment } from "react";

//import Splash from "../../shared/components/UIElements/Splash";

import CategChoose from "../components/CategChoose";

const Menu = () => {
  return (
    <Fragment>
      {/* <Splash show /> */}
      <CategChoose />
    </Fragment>
  );
};

export default Menu;
