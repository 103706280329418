import React, { useState, useCallback, useReducer } from "react";

import { Link, useHistory, useParams } from "react-router-dom";

import { CSSTransition } from "react-transition-group";

import Input from "../../shared/components/FormElements/Input";

import Form from "../../shared/components/UIElements/Form";

import Button from "../../shared/components/FormElements/Button";

import Modal from "../../shared/components/UIElements/Modal";

import ErrorModal from "../../shared/components/UIElements/ErrorModal";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import { VALIDATOR_MINLENGTH } from "../../shared/util/validators";

import "./UserReset.css";

const formReducer = (state, action) => {
  switch (action.type) {
    case "INPUT_CHANGE":
      let formIsValid = true;

      for (const inputId in state.inputs) {
        if (inputId === action.inputId) {
          formIsValid = formIsValid && action.isValid;
        } else {
          formIsValid = formIsValid && state.inputs[inputId].isValid;
        }
      }

      return {
        ...state,

        inputs: {
          ...state.inputs,

          [action.inputId]: { value: action.value, isValid: action.isValid },
        },

        isValid: formIsValid,
      };

    default:
      return state;
  }
};

const UserForgot = () => {
  const [formState, dispatch] = useReducer(formReducer, {
    inputs: {
      password: {
        value: "",

        isValid: false,
      },

      confirmPassword: {
        value: "",

        isValid: false,
      },
    },

    isValid: false,
  });

  const inputHandler = useCallback(
    (id, value, isValid) => {
      dispatch({
        type: "INPUT_CHANGE",

        value: value,

        isValid: isValid,

        inputId: id,
      });
    },

    [dispatch]
  );

  const resetToken = useParams().resetToken;

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState();

  const history = useHistory();

  const resetHandler = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    if (
      formState.inputs.password.value === formState.inputs.confirmPassword.value
    ) {
      try {
        setIsLoading(true);

        const response = await fetch(
          `${process.env.REACT_APP_API_DOMAIN}/v1/auth/resetpassword/${resetToken}`,
          {
            method: "PUT",

            headers: {
              "Content-Type": "application/json",
            },

            body: JSON.stringify({
              password: formState.inputs.password.value,
            }),
          }
        );

        const responseData = await response.json();

        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }

        setIsLoading(false);

        openShowSuccesHandler(true);
      } catch (err) {
        setError(err.message || "Something went wrong please try again");

        setIsLoading(false);
      }
    } else {
      setError("Password and confirm password do not match");
    }
  };

  const errorHandler = () => {
    setError(null);

    setIsLoading(false);

    history.go(0);
  };

  const signInHandler = () => {
    history.push("/signin");
  };

  // Show modal form const

  const [showSuccess, setShowSuccess] = useState(false);

  const openShowSuccesHandler = () => setShowSuccess(true);

  // const closeShowSuccesHandler = () => setShowSuccess(false);

  return (
    <React.Fragment>
      <Modal
        show={showSuccess}
        // onCancel={closeShowSuccessHandler}

        header={"Success"}
        contentClass="menu-selected__modal-content"
        footerClass="menu-selected__modal-actions"
      >
        <p>Your password successfully change!!!</p>

        <footer>
          <Button className="btn" onClick={signInHandler}>
            Ok
          </Button>
        </footer>
      </Modal>

      <ErrorModal error={error} onClear={errorHandler} />

      <CSSTransition
        classNames={{
          appear: "slide-in-left-enter",

          appearActive: "slide-in-left-enter-active",
        }}
        timeout={1000}
        in
        appear
        unmountOnExit
      >
        <section className="container user-reset-container">
          {isLoading && <LoadingSpinner asOverlay />}

          <h1 className="medium text-primary">RESETARE PAROLA</h1>

          <p className="lead">
            <i className="fas fa-user"></i>INTRODUCETI PAROLA
          </p>

          <Form className="form" onSubmit={resetHandler}>
            <div className="form-group">
              <Input
                id="password"
                element="input"
                type="password"
                placeholder="Password"
                validators={[VALIDATOR_MINLENGTH(8)]}
                errorText="Minimum length 8"
                onInput={inputHandler}
              />

              <Input
                id="confirmPassword"
                element="input"
                type="password"
                placeholder="Confirm Password"
                validators={[VALIDATOR_MINLENGTH(8)]}
                errorText="Minimum length 8"
                onInput={inputHandler}
              />
            </div>

            <Button id="btnSubmit" type="submit" disabled={!formState.isValid}>
              RESET
            </Button>
          </Form>

          <p className="my-1">
            A expirat tokenul de resetare?{" "}
            <Link to={"/forgot"}>Resetează Parola</Link>
          </p>
        </section>
      </CSSTransition>
    </React.Fragment>
  );
};

export default UserForgot;
