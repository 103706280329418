import React, { useState, useContext, useEffect } from "react";

import ImagePreview from "./ImagePreview";

import { useHistory } from "react-router-dom";

import { CSSTransition } from "react-transition-group";

import Button from "../../shared/components/FormElements/Button";

import Modal from "../../shared/components/UIElements/Modal";

import Form from "../../shared/components/UIElements/Form";

import Input from "../../shared/components/FormElements/Input";

import ErrorModal from "../../shared/components/UIElements/ErrorModal";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

//import ImageUpload from '../../shared/components/FormElements/ImageUpload';

import { AuthContext } from "../../shared/components/Context/auth-context";

import { VALIDATOR_REQUIRE } from "../../shared/util/validators";

import { useForm } from "../../shared/hooks/form-hooks";

import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";

import "./AddMenu.css";
import "./ImagePreview.css";

const AddMenu = () => {
  const auth = useContext(AuthContext);

  const [showSubcateg, setShowSubcateg] = useState("");
  const [formState, inputHandler] = useForm(
    {
      name: {
        value: "",

        isValid: true,
      },

      description: {
        value: "",

        isValid: true,
      },

      price: {
        value: "",

        isValid: true,
      },

      color: {
        value: "",

        isValid: true,
      },

      brand: {
        value: "",

        isValid: true,
      },

      dimensions: {
        value: "",

        isValid: true,
      },

      quantity: {
        value: "",

        isValid: true,
      },

      material: {
        value: "",

        isValid: true,
      },
    },

    true
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [dataImg, setDataImg] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [isCameraOn, setIsCameraOn] = useState(true);

  const history = useHistory();

  const menuSubmitHandler = async (event) => {
    event.preventDefault();
    // Select option availability
    const e = document.getElementById("selectAvailable");
    const available = e.options[e.selectedIndex].value;
    //Checkbox category
    let category = document.getElementsByClassName("category");
    const checkboxesChecked = [];

    for (let i = 0; i < category.length; i++) {
      // And stick the checked ones onto an array...
      if (category[i].checked === true) {
        checkboxesChecked.push(category[i].value);
      }
    }

    //Checkbox subcategory
    let subcategory = document.getElementsByClassName("subcategory");
    const checkboxesCheckedSub = [];

    for (let i = 0; i < subcategory.length; i++) {
      if (subcategory[i].checked === true) {
        checkboxesCheckedSub.push(subcategory[i].value);
      }
    }

    if (checkboxesChecked.length !== 0 && checkboxesCheckedSub.length !== 0) {
      try {
        setIsLoading(true);

        const formData = new FormData();
        formData.append("name", formState.inputs.name.value);
        formData.append("description", formState.inputs.description.value);
        formData.append("price", formState.inputs.price.value);
        formData.append("color", formState.inputs.color.value);
        formData.append("brand", formState.inputs.brand.value);
        formData.append("dimensions", formState.inputs.dimensions.value);
        formData.append("material", formState.inputs.material.value);
        formData.append("quantity", formState.inputs.quantity.value);
        formData.append("available", available);
        formData.append("category", checkboxesChecked);
        formData.append("subcategory", checkboxesCheckedSub);

        if (fileData.length > 0) {
          for (var i = 0; i < fileData.length; i++) {
            var file = fileData[i];
            if (i === 0) {
              formData.append(
                "imageMain",
                file,
                formState.inputs.name.value + "_1"
              );
            } else {
              formData.append(
                "image" + (i - 1),
                file,
                formState.inputs.name.value + "_" + (i + 1)
              );
            }
          }
        }
        const response = await fetch(          
          `${process.env.REACT_APP_API_DOMAIN}/v1/menus`,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + auth.token,
            },
            body: formData,
          }
        );
        const responseData = await response.json();

        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }
        setIsLoading(false);
        openShowSuccesHandler(true);
      } catch (err) {      
        if (err.message === "Camp duplicat") {
          setError("Produsul este deja adaugat");
        } else {
          setError("A aparut o problema la adaugarea produsului");
        }
        setIsLoading(false);
      }
    } else {
      setError("Selecteaza o categorie si o subcategorie");
    }
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const errorHandler = () => {
    setError(null);
  };

  // Show modal form const
  const [showSuccess, setShowSuccess] = useState(false);
  const [addedToAFamilly, setAddedToAFamilly] = useState(false);
  const openShowSuccesHandler = () => setShowSuccess(true);
  const handleOnClickAddToAFamilly = () =>{
    setAddedToAFamilly(true);
  }
  const closeShowSuccesHandler = () => setShowSuccess(false);

  //on take photo
  function handleTakePhoto(dataUri) {
    //adaugam base64 imaginile in array(state)
    setDataImg([...dataImg, dataUri]);

    //transformam fiecare base64 in File si adaugam in array (state)
    let name = "numeimg";
    const file = dataURLtoFile(dataUri, name);

    setFileData([...fileData, file]);
  }

  useEffect(() => {
    if (dataImg.length === 3) {
      setIsCameraOn(false);
    }
  }, [dataImg]);

  const reopenCamera = () => {
    //width: 509, height: 906
    //new width: 709, height: 1206
    setDataImg([]);
    setFileData([]);
    setIsCameraOn(true);
  };

  const handleOnClickSubcateg = (event) => {
    if (showSubcateg === "") {
      setShowSubcateg(event.target.value);
    } else {
      setShowSubcateg("");
    }
  };

  return (
    <React.Fragment>
      <Modal
        show={showSuccess}
        onCancel={closeShowSuccesHandler}
        header={"Success"}
        contentClass="menu-selected__modal-content"
        footerClass="menu-selected__modal-actions"
      >
        <p>Produs adaugat cu succes!!!</p>

        <footer>
          <Button className="btn" onClick={addedToAFamilly ? closeShowSuccesHandler : history.go}>
            Ok
          </Button>
        </footer>
      </Modal>

      <ErrorModal error={error} onClear={errorHandler} />
      <CSSTransition
        classNames={{
          appear: "slide-in-left-enter",

          appearActive: "slide-in-left-enter-active",
        }}
        timeout={1000}
        in
        appear
        unmountOnExit
      >
        <Form className="menu-form" onSubmit={menuSubmitHandler}>
          {isLoading && <LoadingSpinner asOverlay />}
          <div>
            <label htmlFor="familly">
              Adauga intr-o familie{" "}
              <input
                id="familly"
                className="subcategory"
                type="checkbox" 
                onClick={handleOnClickAddToAFamilly}               
              />
            </label>
            <ImagePreview dataUri={dataImg} isFullscreen={false} />
            {isCameraOn ? (
              <Camera
                className="menu-take-photo"
                isImageMirror={false}
                idealFacingMode={FACING_MODES.ENVIRONMENT}
                idealResolution={{ width: 709, height: 1206 }}
                imageType={IMAGE_TYPES.JPG}
                onTakePhoto={(dataUri) => {
                  handleTakePhoto(dataUri);
                }}
              />
            ) : (
              <Button onClick={reopenCamera}>Redeschide Camera</Button>
            )}
          </div>

          <div className="form-group">
            {/* <ImageUpload id='imageCapture' onInput={inputHandler} /> */}
            <Input
              id="name"
              element="input"
              type="text"
              label="NUME PRODUS"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Introduceti numele"
              onInput={inputHandler}
            />
            <Input
              id="description"
              element="textarea"
              type="text"
              rows="8"
              label="DESCRIERE"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Introduceti descrierea"
              onInput={inputHandler}
            />
            CATEGORIA
            <fieldset>
              <div className="item">
                <label className="accesoriimobila" htmlFor="accesoriimobila">
                  Accesorii mobilă{" "}
                  <input
                    id="accesoriimobila"
                    className="category"
                    type="checkbox"
                    value="accesorii-mobila"
                    onClick={handleOnClickSubcateg}
                  />
                  {showSubcateg === "accesorii-mobila" && (
                    <div className="subcateg__container">
                      <fieldset>
                        <div className="item">
                          <label
                            className="manere-si-butoni"
                            htmlFor="manere-si-butoni"
                          >
                            Mânere și butoni{" "}
                            <input
                              id="manere-si-butoni"
                              className="subcategory"
                              type="checkbox"
                              value="manere-si-butoni"
                            />
                          </label>
                          <label className="balamale" htmlFor="balamale">
                            Balamale{" "}
                            <input
                              id="balamale"
                              className="subcategory"
                              type="checkbox"
                              value="balamale"
                            />
                          </label>
                          <label className="blocatori" htmlFor="blocatori">
                            Blocatori{" "}
                            <input
                              id="blocatori"
                              className="subcategory"
                              type="checkbox"
                              value="blocatori"
                            />
                          </label>
                          <label className="broaste" htmlFor="broaste">
                            Broaște{" "}
                            <input
                              id="broaste"
                              className="subcategory"
                              type="checkbox"
                              value="broaste"
                            />
                          </label>
                          <label className="chei" htmlFor="chei">
                            Chei{" "}
                            <input
                              id="chei"
                              className="subcategory"
                              type="checkbox"
                              value="chei"
                            />
                          </label>
                          <label className="sild" htmlFor="sild">
                            Șild{" "}
                            <input
                              id="sild"
                              className="subcategory"
                              type="checkbox"
                              value="sild"
                            />
                          </label>
                          <label className="bucsa" htmlFor="bucsa">
                            Bucșa{" "}
                            <input
                              id="bucsa"
                              className="subcategory"
                              type="checkbox"
                              value="bucsa"
                            />
                          </label>
                          <label className="magnet" htmlFor="magnet">
                            Magnet{" "}
                            <input
                              id="magnet"
                              className="subcategory"
                              type="checkbox"
                              value="magnet"
                            />
                          </label>
                        </div>
                      </fieldset>
                    </div>
                  )}
                </label>
                <label
                  className="accesoriitapiterie"
                  htmlFor="accesoriitapiterie"
                >
                  Accesorii tapițerie{" "}
                  <input
                    id="accesoriitapiterie"
                    className="category"
                    type="checkbox"
                    value="accesorii-tapiterie"
                    onClick={handleOnClickSubcateg}
                  />
                  {showSubcateg === "accesorii-tapiterie" && (
                    <div className="subcateg__container">
                      <div className="item">
                        <fieldset>
                          <label
                            className="cuie-false-tapiterie-banda-bronz"
                            htmlFor="cuie-false-tapiterie-banda-bronz"
                          >
                            Cuie false tapițerie bandă bronz{" "}
                            <input
                              id="cuie-false-tapiterie-banda-bronz"
                              className="subcategory"
                              type="checkbox"
                              value="cuie-false-tapiterie-banda-bronz"
                            />
                          </label>
                          <label
                            className="cuie-false-tapiterie-banda-argint"
                            htmlFor="cuie-false-tapiterie-banda-argint"
                          >
                            Cuie false tapițerie bandă argint{" "}
                            <input
                              id="cuie-false-tapiterie-banda-argint"
                              className="subcategory"
                              type="checkbox"
                              value="cuie-false-tapiterie-banda-argint"
                            />
                          </label>
                          <label
                            className="cuie-false-tapiterie-banda-funuriu"
                            htmlFor="cuie-false-tapiterie-banda-funuriu"
                          >
                            Cuie false tapițerie bandă funuriu{" "}
                            <input
                              id="cuie-false-tapiterie-banda-funuriu"
                              className="subcategory"
                              type="checkbox"
                              value="cuie-false-tapiterie-banda-funuriu"
                            />
                          </label>
                          <label
                            className="cuie-false-tapiterie-banda-continua"
                            htmlFor="cuie-false-tapiterie-banda-continua"
                          >
                            Cuie false tapițerie bandă continuă{" "}
                            <input
                              id="cuie-false-tapiterie-banda-continua"
                              className="subcategory"
                              type="checkbox"
                              value="cuie-false-tapiterie-banda-continua"
                            />
                          </label>
                          <label
                            className="cuie-tapiterie-D24"
                            htmlFor="cuie-tapiterie-D24"
                          >
                            Cuie tapițerie D24{" "}
                            <input
                              id="cuie-tapiterie-D24"
                              className="subcategory"
                              type="checkbox"
                              value="cuie-tapiterie-D24"
                            />
                          </label>
                          <label
                            className="cuie-tapiterie-D20"
                            htmlFor="cuie-tapiterie-D20"
                          >
                            Cuie tapițerie D20{" "}
                            <input
                              id="cuie-tapiterie-D20"
                              className="subcategory"
                              type="checkbox"
                              value="cuie-tapiterie-D20"
                            />
                          </label>
                          <label
                            className="cuie-tapiterie-patrate"
                            htmlFor="cuie-tapiterie-patrate"
                          >
                            Cuie tapițerie pătrate{" "}
                            <input
                              id="cuie-tapiterie-patrate"
                              className="subcategory"
                              type="checkbox"
                              value="cuie-tapiterie-patrate"
                            />
                          </label>
                          <label
                            className="capse-U-tapiterie-80-04"
                            htmlFor="capse-U-tapiterie-80-04"
                          >
                            Capse U tapițerie 80-04{" "}
                            <input
                              id="capse-U-tapiterie-80-04"
                              className="subcategory"
                              type="checkbox"
                              value="capse-U-tapiterie-80-04"
                            />
                          </label>
                          <label
                            className="capse-U-tapiterie-80-06"
                            htmlFor="capse-U-tapiterie-80-06"
                          >
                            Capse U tapițerie 80-06{" "}
                            <input
                              id="capse-U-tapiterie-80-06"
                              className="subcategory"
                              type="checkbox"
                              value="capse-U-tapiterie-80-06"
                            />
                          </label>
                          <label
                            className="capse-U-tapiterie-80-08"
                            htmlFor="capse-U-tapiterie-80-08"
                          >
                            Capse U tapițerie 80-08{" "}
                            <input
                              id="capse-U-tapiterie-80-08"
                              className="subcategory"
                              type="checkbox"
                              value="capse-U-tapiterie-80-08"
                            />
                          </label>
                          <label
                            className="capse-U-tapiterie-80-10"
                            htmlFor="capse-U-tapiterie-80-10"
                          >
                            Capse U tapițerie 80-10{" "}
                            <input
                              id="capse-U-tapiterie-80-10"
                              className="subcategory"
                              type="checkbox"
                              value="capse-U-tapiterie-80-10"
                            />
                          </label>
                          <label
                            className="capse-U-tapiterie-80-12"
                            htmlFor="capse-U-tapiterie-80-12"
                          >
                            Capse U tapițerie 80-12{" "}
                            <input
                              id="capse-U-tapiterie-80-12"
                              className="subcategory"
                              type="checkbox"
                              value="capse-U-tapiterie-80-12"
                            />
                          </label>
                          <label
                            className="capse-U-tapiterie-80-14"
                            htmlFor="capse-U-tapiterie-80-14"
                          >
                            Capse U tapițerie 80-14{" "}
                            <input
                              id="capse-U-tapiterie-80-14"
                              className="subcategory"
                              type="checkbox"
                              value="capse-U-tapiterie-80-14"
                            />
                          </label>
                          <label
                            className="capse-U-tapiterie-80-16"
                            htmlFor="capse-U-tapiterie-80-16"
                          >
                            Capse U tapițerie 80-16{" "}
                            <input
                              id="capse-U-tapiterie-80-16"
                              className="subcategory"
                              type="checkbox"
                              value="capse-U-tapiterie-80-16"
                            />
                          </label>
                          <label
                            className="capse-U-tapiterie-SJK-8"
                            htmlFor="capse-U-tapiterie-SJK-8"
                          >
                            Capse U tapițerie SJK 8{" "}
                            <input
                              id="capse-U-tapiterie-SJK-8"
                              className="subcategory"
                              type="checkbox"
                              value="capse-U-tapiterie-SJK-8"
                            />
                          </label>
                          <label
                            className="pistol-capse-U-80-16"
                            htmlFor="pistol-capse-U-80-16"
                          >
                            Pistol Capse U 80-16{" "}
                            <input
                              id="pistol-capse-U-80-16"
                              className="subcategory"
                              type="checkbox"
                              value="pistol-capse-U-80-16"
                            />
                          </label>
                        </fieldset>
                      </div>
                    </div>
                  )}
                </label>

                <label
                  className="ornamentemobilalemn"
                  htmlFor="ornamentemobilalemn"
                >
                  Ornamente mobila lemn{" "}
                  <input
                    id="ornamentemobilalemn"
                    className="category"
                    type="checkbox"
                    value="ornamente-mobila-lemn"
                    onClick={handleOnClickSubcateg}
                  />
                  {showSubcateg === "ornamente-mobila-lemn" && (
                    <div className="subcateg__container">
                      <div className="item">
                        <fieldset>
                          <label
                            className="ornamente-lemn-sculptat-bara"
                            htmlFor="ornamente-lemn-sculptat-bara"
                          >
                            Ornamente lemn sculptat bară{" "}
                            <input
                              id="ornamente-lemn-sculptat-bara"
                              className="subcategory"
                              type="checkbox"
                              value="ornamente-lemn-sculptat-bara"
                            />
                          </label>
                          <label
                            className="ornament-mdf-presat"
                            htmlFor="ornament-mdf-presat"
                          >
                            Ornament MDF presat{" "}
                            <input
                              id="ornament-mdf-presat"
                              className="subcategory"
                              type="checkbox"
                              value="ornament-mdf-presat"
                            />
                          </label>
                        </fieldset>
                      </div>
                    </div>
                  )}
                </label>
                <label className="menghinetamplar" htmlFor="menghinetamplar">
                  Menghine tâmplar{" "}
                  <input
                    id="menghinetamplar"
                    className="category"
                    type="checkbox"
                    value="menghine-tamplar"
                    onClick={handleOnClickSubcateg}
                  />
                  {showSubcateg === "menghine-tamplar" && (
                    <div className="subcateg__container">
                      <div className="item">
                        <fieldset>
                          <label
                            className="menghine-350-mm"
                            htmlFor="menghine-350-mm"
                          >
                            Menghine 350 mm{" "}
                            <input
                              id="menghine-350-mm"
                              className="subcategory"
                              type="checkbox"
                              value="menghine-350-mm"
                            />
                          </label>
                          <label
                            className="menghine-400-mm"
                            htmlFor="menghine-400-mm"
                          >
                            Menghine 400 mm{" "}
                            <input
                              id="menghine-400-mm"
                              className="subcategory"
                              type="checkbox"
                              value="menghine-400-mm"
                            />
                          </label>
                          <label
                            className="menghine-500-mm"
                            htmlFor="menghine-500-mm"
                          >
                            Menghine 500 mm{" "}
                            <input
                              id="menghine-500-mm"
                              className="subcategory"
                              type="checkbox"
                              value="menghine-500-mm"
                            />
                          </label>
                          <label
                            className="menghine-600-mm"
                            htmlFor="menghine-600-mm"
                          >
                            Menghine 600 mm{" "}
                            <input
                              id="menghine-600-mm"
                              className="subcategory"
                              type="checkbox"
                              value="menghine-600-mm"
                            />
                          </label>
                          <label
                            className="menghine-700-mm"
                            htmlFor="menghine-700-mm"
                          >
                            Menghine 700 mm{" "}
                            <input
                              id="menghine-700-mm"
                              className="subcategory"
                              type="checkbox"
                              value="menghine-700-mm"
                            />
                          </label>
                          <label
                            className="menghine-800-mm"
                            htmlFor="menghine-800-mm"
                          >
                            Menghine 800 mm{" "}
                            <input
                              id="menghine-800-mm"
                              className="subcategory"
                              type="checkbox"
                              value="menghine-800-mm"
                            />
                          </label>
                          <label
                            className="menghine-1000-mm"
                            htmlFor="menghine-1000-mm"
                          >
                            Menghine 1000 mm{" "}
                            <input
                              id="menghine-1000-mm"
                              className="subcategory"
                              type="checkbox"
                              value="menghine-1000-mm"
                            />
                          </label>
                          <label
                            className="menghine-1200-mm"
                            htmlFor="menghine-1200-mm"
                          >
                            Menghine 1200 mm{" "}
                            <input
                              id="menghine-1200-mm"
                              className="subcategory"
                              type="checkbox"
                              value="menghine-1200-mm"
                            />
                          </label>
                        </fieldset>
                      </div>
                    </div>
                  )}
                </label>
                <label className="adezivichituri" htmlFor="adezivichituri">
                  Adezivi și chituri pentru lemn{" "}
                  <input
                    id="adezivichituri"
                    className="category"
                    type="checkbox"
                    value="adezivi-si-chituri-pentru-lemn"
                    onClick={handleOnClickSubcateg}
                  />
                  {showSubcateg === "adezivi-si-chituri-pentru-lemn" && (
                    <div className="subcateg__container">
                      <div className="item">
                        <fieldset>
                          <label className="aracet-d3" htmlFor="aracet-d3">
                            Aracet D3{" "}
                            <input
                              id="aracet-d3"
                              className="subcategory"
                              type="checkbox"
                              value="aracet-d3"
                            />
                          </label>
                          <label className="aracet-d4" htmlFor="aracet-d4">
                            Aracet D4{" "}
                            <input
                              id="aracet-d4"
                              className="subcategory"
                              type="checkbox"
                              value="aracet-d4"
                            />
                          </label>
                          <label className="aracet-d2" htmlFor="aracet-d2">
                            Aracet D2{" "}
                            <input
                              id="aracet-d2"
                              className="subcategory"
                              type="checkbox"
                              value="aracet-d2"
                            />
                          </label>
                          <label
                            className="adeziv-urelit-la-cald-pentru-presa"
                            htmlFor="adeziv-urelit-la-cald-pentru-presa"
                          >
                            Adeziv URELIT la cald pentru presa{" "}
                            <input
                              id="adeziv-urelit-la-cald-pentru-presa"
                              className="subcategory"
                              type="checkbox"
                              value="adeziv-urelit-la-cald-pentru-presa"
                            />
                          </label>
                          <label
                            className="chit-colorat-pe-baza-de-apa"
                            htmlFor="chit-colorat-pe-baza-de-apa"
                          >
                            Chit colorat pe baza de apa{" "}
                            <input
                              id="chit-colorat-pe-baza-de-apa"
                              className="subcategory"
                              type="checkbox"
                              value="chit-colorat-pe-baza-de-apa"
                            />
                          </label>
                          <label
                            className="baton-ceara-corectoare"
                            htmlFor="baton-ceara-corectoare"
                          >
                            Baton ceara corectoare{" "}
                            <input
                              id="baton-ceara-corectoare"
                              className="subcategory"
                              type="checkbox"
                              value="baton-ceara-corectoare"
                            />
                          </label>
                          <label
                            className="clei-de-oase-granulat"
                            htmlFor="clei-de-oase-granulat"
                          >
                            Clei de oase granulat{" "}
                            <input
                              id="clei-de-oase-granulat"
                              className="subcategory"
                              type="checkbox"
                              value="clei-de-oase-granulat"
                            />
                          </label>
                          <label
                            className="dopuri-lemn-reparatii"
                            htmlFor="dopuri-lemn-reparatii"
                          >
                            Dopuri lemn reparatii{" "}
                            <input
                              id="dopuri-lemn-reparatii"
                              className="subcategory"
                              type="checkbox"
                              value="dopuri-lemn-reparatii"
                            />
                          </label>
                          <label
                            className="barcute-lemn-reparatii"
                            htmlFor="barcute-lemn-reparatii"
                          >
                            Barcute lemn reparatii{" "}
                            <input
                              id="barcute-lemn-reparatii"
                              className="subcategory"
                              type="checkbox"
                              value="barcute-lemn-reparatii"
                            />
                          </label>
                          <label className="lamele-lemn" htmlFor="lamele-lemn">
                            Lamele lemn{" "}
                            <input
                              id="lamele-lemn"
                              className="subcategory"
                              type="checkbox"
                              value="lamele-lemn"
                            />
                          </label>
                          <label
                            className="dibluri-lemn"
                            htmlFor="dibluri-lemn"
                          >
                            Dibluri lemn{" "}
                            <input
                              id="dibluri-lemn"
                              className="subcategory"
                              type="checkbox"
                              value="dibluri-lemn"
                            />
                          </label>
                        </fieldset>
                      </div>
                    </div>
                  )}
                </label>
                <label className="trataresuprafete" htmlFor="trataresuprafete">
                  Soluții tratare suprafețe{" "}
                  <input
                    id="trataresuprafete"
                    className="category"
                    type="checkbox"
                    value="solutii-pentru-tratarea-lemnului"
                    onClick={handleOnClickSubcateg}
                  />
                  {showSubcateg === "solutii-pentru-tratarea-lemnului" && (
                    <div className="subcateg__container">
                      <div className="item">
                        <fieldset>
                          <label
                            className="ceara-borma-wachs"
                            htmlFor="ceara-borma-wachs"
                          >
                            Ceara Borma Wachs{" "}
                            <input
                              id="ceara-borma-wachs"
                              className="subcategory"
                              type="checkbox"
                              value="ceara-borma-wachs"
                            />
                          </label>
                          <label
                            className="ulei-parchet"
                            htmlFor="ulei-parchet"
                          >
                            Ulei parchet{" "}
                            <input
                              id="ulei-parchet"
                              className="subcategory"
                              type="checkbox"
                              value="ulei-parchet"
                            />
                          </label>
                          <label className="anticarie" htmlFor="anticarie">
                            Anticarie{" "}
                            <input
                              id="anticarie"
                              className="subcategory"
                              type="checkbox"
                              value="anticarie"
                            />
                          </label>
                          <label
                            className="serlac-fulgi"
                            htmlFor="serlac-fulgi"
                          >
                            Șerlac fulgi{" "}
                            <input
                              id="serlac-fulgi"
                              className="subcategory"
                              type="checkbox"
                              value="serlac-fulgi"
                            />
                          </label>
                          <label
                            className="bait-colorat-lichid"
                            htmlFor="bait-colorat-lichid"
                          >
                            Bait colorat lichid{" "}
                            <input
                              id="bait-colorat-lichid"
                              className="subcategory"
                              type="checkbox"
                              value="bait-colorat-lichid"
                            />
                          </label>
                          <label
                            className="bait-colorat-granule"
                            htmlFor="bait-colorat-granule"
                          >
                            Bait colorat granule{" "}
                            <input
                              id="bait-colorat-granule"
                              className="subcategory"
                              type="checkbox"
                              value="bait-colorat-granule"
                            />
                          </label>
                          <label
                            className="diluant-nitro"
                            htmlFor="diluant-nitro"
                          >
                            Diluan Nitro{" "}
                            <input
                              id="diluant-nitro"
                              className="subcategory"
                              type="checkbox"
                              value="diluant-nitro"
                            />
                          </label>
                          <label
                            className="alcool-tehnic"
                            htmlFor="alcool-tehnic"
                          >
                            Alcool tehnic{" "}
                            <input
                              id="alcool-tehnic"
                              className="subcategory"
                              type="checkbox"
                              value="alcool-tehnic"
                            />
                          </label>
                          <label className="patina" htmlFor="patina">
                            Patina{" "}
                            <input
                              id="patina"
                              className="subcategory"
                              type="checkbox"
                              value="patina"
                            />
                          </label>
                          <label
                            className="carioca-corectare-cu-lac"
                            htmlFor="carioca-corectare-cu-lac"
                          >
                            Carioca corectare cu lac{" "}
                            <input
                              id="carioca-corectare-cu-lac"
                              className="subcategory"
                              type="checkbox"
                              value="carioca-corectare-cu-lac"
                            />
                          </label>
                        </fieldset>
                      </div>
                    </div>
                  )}
                </label>
                <label className="cuiecapseagrafe" htmlFor="cuiecapseagrafe">
                  Cuie capse și agrafe{" "}
                  <input
                    id="cuiecapseagrafe"
                    className="category"
                    type="checkbox"
                    value="cuie-capse-si-agrafe"
                    onClick={handleOnClickSubcateg}
                  />
                  {showSubcateg === "cuie-capse-si-agrafe" && (
                    <div className="subcateg__container">
                      <div className="item">
                        <fieldset>
                          <label
                            className="cuie-serie-np-12-cu-cap"
                            htmlFor="cuie-serie-np-12-cu-cap"
                          >
                            Cuie serie NP 12 cu cap{" "}
                            <input
                              id="cuie-serie-np-12-cu-cap"
                              className="subcategory"
                              type="checkbox"
                              value="cuie-serie-np-12-cu-cap"
                            />
                          </label>
                          <label
                            className="cuie-serie-ng-14-cu-cap"
                            htmlFor="cuie-serie-ng-14-cu-cap"
                          >
                            Cuie serie NG 14 cu cap{" "}
                            <input
                              id="cuie-serie-ng-14-cu-cap"
                              className="subcategory"
                              type="checkbox"
                              value="cuie-serie-ng-14-cu-cap"
                            />
                          </label>
                          <label
                            className="cuie-serie-mg-cu-cap"
                            htmlFor="cuie-serie-mg-cu-cap"
                          >
                            Cuie serie MG cu cap{" "}
                            <input
                              id="cuie-serie-mg-cu-cap"
                              className="subcategory"
                              type="checkbox"
                              value="cuie-serie-mg-cu-cap"
                            />
                          </label>
                          <label
                            className="cuie-serie-mg-fara-cap"
                            htmlFor="cuie-serie-mg-fara-cap"
                          >
                            Cuie serie MG fara cap{" "}
                            <input
                              id="cuie-serie-mg-fara-cap"
                              className="subcategory"
                              type="checkbox"
                              value="cuie-serie-mg-fara-cap"
                            />
                          </label>
                          <label
                            className="cuie-serie-j-fara-cap"
                            htmlFor="cuie-serie-j-fara-cap"
                          >
                            Cuie serie J fara cap{" "}
                            <input
                              id="cuie-serie-j-fara-cap"
                              className="subcategory"
                              type="checkbox"
                              value="cuie-serie-j-fara-cap"
                            />
                          </label>
                          <label
                            className="mini-cuie-0,6-mm-15"
                            htmlFor="mini-cuie-0,6-mm-15"
                          >
                            Mini cuie 0,6mm 15{" "}
                            <input
                              id="mini-cuie-0,6-mm-15"
                              className="subcategory"
                              type="checkbox"
                              value="mini-cuie-0,6-mm-15"
                            />
                          </label>
                          <label
                            className="mini-cuie-0,6-mm-18"
                            htmlFor="mini-cuie-0,6-mm-18"
                          >
                            Mini cuie 0,6mm 18{" "}
                            <input
                              id="mini-cuie-0,6-mm-18"
                              className="subcategory"
                              type="checkbox"
                              value="mini-cuie-0,6-mm-18"
                            />
                          </label>
                          <label
                            className="mini-cuie-0,6-mm-25"
                            htmlFor="mini-cuie-0,6-mm-25"
                          >
                            Mini cuie 0,6mm 25{" "}
                            <input
                              id="mini-cuie-0,6-mm-25"
                              className="subcategory"
                              type="checkbox"
                              value="mini-cuie-0,6-mm-25"
                            />
                          </label>
                          <label
                            className="mini-cuie-0,6-mm-30"
                            htmlFor="mini-cuie-0,6-mm-30"
                          >
                            Mini cuie 0,6mm 30{" "}
                            <input
                              id="mini-cuie-0,6-mm-30"
                              className="subcategory"
                              type="checkbox"
                              value="mini-cuie-0,6-mm-30"
                            />
                          </label>
                        </fieldset>
                      </div>
                    </div>
                  )}
                </label>
                <label className="abrazivelemn" htmlFor="abrazivelemn">
                  Abrazive lemn{" "}
                  <input
                    id="abrazivelemn"
                    className="category"
                    type="checkbox"
                    value="abrazive-pentru-lemn"
                    onClick={handleOnClickSubcateg}
                  />
                  {showSubcateg === "abrazive-pentru-lemn" && (
                    <div className="subcateg__container">
                      <div className="item">
                        <fieldset>
                          <label
                            className="pasla-abraziva-rola"
                            htmlFor="pasla-abraziva-rola"
                          >
                            Pasla abraziva rola{" "}
                            <input
                              id="pasla-abraziva-rola"
                              className="subcategory"
                              type="checkbox"
                              value="pasla-abraziva-rola"
                            />
                          </label>
                          <label
                            className="panza-abraziva-rola"
                            htmlFor="panza-abraziva-rola"
                          >
                            Panza abraziva rola{" "}
                            <input
                              id="panza-abraziva-rola"
                              className="subcategory"
                              type="checkbox"
                              value="panza-abraziva-rola"
                            />
                          </label>
                          <label
                            className="hartie-abraziva-rola"
                            htmlFor="hartie-abraziva-rola"
                          >
                            Hartie abraziva rola{" "}
                            <input
                              id="hartie-abraziva-rola"
                              className="subcategory"
                              type="checkbox"
                              value="hartie-abraziva-rola"
                            />
                          </label>
                          <label
                            className="discuri-abraziv-d150"
                            htmlFor="discuri-abraziv-d150"
                          >
                            Discuri abraziv D150{" "}
                            <input
                              id="discuri-abraziv-d150"
                              className="subcategory"
                              type="checkbox"
                              value="discuri-abraziv-d150"
                            />
                          </label>
                          <label
                            className="discuri-abraziv-d125"
                            htmlFor="discuri-abraziv-d125"
                          >
                            Discuri abraziv D125{" "}
                            <input
                              id="discuri-abraziv-d125"
                              className="subcategory"
                              type="checkbox"
                              value="discuri-abraziv-d125"
                            />
                          </label>
                          <label
                            className="lana-otelata"
                            htmlFor="lana-otelata"
                          >
                            Lână oțelată{" "}
                            <input
                              id="lana-otelata"
                              className="subcategory"
                              type="checkbox"
                              value="lana-otelata"
                            />
                          </label>
                          <label
                            className="banda-533x75"
                            htmlFor="banda-533x75"
                          >
                            Bandă 533x75{" "}
                            <input
                              id="banda-533x75"
                              className="subcategory"
                              type="checkbox"
                              value="banda-533x75"
                            />
                          </label>
                          <label
                            className="banda-457x75"
                            htmlFor="banda-457x75"
                          >
                            Bandă 457x75{" "}
                            <input
                              id="banda-457x75"
                              className="subcategory"
                              type="checkbox"
                              value="banda-457x75"
                            />
                          </label>
                          <label
                            className="perie-teflon-makita"
                            htmlFor="perie-teflon-makita"
                          >
                            Perie teflon Makita{" "}
                            <input
                              id="perie-teflon-makita"
                              className="subcategory"
                              type="checkbox"
                              value="perie-teflon-makita"
                            />
                          </label>
                          <label
                            className="perie-otel-makita"
                            htmlFor="perie-otel-makita"
                          >
                            Perie otel Makita{" "}
                            <input
                              id="perie-otel-makita"
                              className="subcategory"
                              type="checkbox"
                              value="perie-otel-makita"
                            />
                          </label>
                          <label
                            className="burete-abraziv-rola-ass"
                            htmlFor="burete-abraziv-rola-ass"
                          >
                            Burete abraziv rola ass{" "}
                            <input
                              id="burete-abraziv-rola-ass"
                              className="subcategory"
                              type="checkbox"
                              value="burete-abraziv-rola-ass"
                            />
                          </label>
                          <label
                            className="burete-abraziv-rola-rosu"
                            htmlFor="burete-abraziv-rola-rosu"
                          >
                            Burete abraziv rola rosu{" "}
                            <input
                              id="burete-abraziv-rola-rosu"
                              className="subcategory"
                              type="checkbox"
                              value="burete-abraziv-rola-rosu"
                            />
                          </label>
                          <label
                            className="burete-abraziv-4-laturi"
                            htmlFor="burete-abraziv-4-laturi"
                          >
                            Burete abraziv 4 laturi{" "}
                            <input
                              id="burete-abraziv-4-laturi"
                              className="subcategory"
                              type="checkbox"
                              value="burete-abraziv-4-laturi"
                            />
                          </label>
                          <label
                            className="burete-abraziv-2-laturi"
                            htmlFor="burete-abraziv-2-laturi"
                          >
                            Burete abraziv 2 laturi{" "}
                            <input
                              id="burete-abraziv-2-laturi"
                              className="subcategory"
                              type="checkbox"
                              value="burete-abraziv-2-laturi"
                            />
                          </label>
                        </fieldset>
                      </div>
                    </div>
                  )}
                </label>
                <label
                  className="pneumaticevopsitorie"
                  htmlFor="pneumaticevopsitorie"
                >
                  Pneumatice și vopsitorie{" "}
                  <input
                    id="pneumaticevopsitorie"
                    className="category"
                    type="checkbox"
                    value="pistoale-pneumatice-si-vopsitorie"
                    onClick={handleOnClickSubcateg}
                  />
                  {showSubcateg === "pistoale-pneumatice-si-vopsitorie" && (
                    <div className="subcateg__container">
                      <div className="item">
                        <fieldset>
                          <label
                            className="pistoale-pneumatice"
                            htmlFor="pistoale-pneumatice"
                          >
                            Pistoale pneumatice{" "}
                            <input
                              id="pistoale-pneumatice"
                              className="subcategory"
                              type="checkbox"
                              value="pistoale-pneumatice"
                            />
                          </label>
                          <label
                            className="pistol-de-vopsit-pentru-lacuri"
                            htmlFor="pistol-de-vopsit-pentru-lacuri"
                          >
                            Pistol de vopsit pentru lacuri{" "}
                            <input
                              id="pistol-de-vopsit-pentru-lacuri"
                              className="subcategory"
                              type="checkbox"
                              value="pistol-de-vopsit-pentru-lacuri"
                            />
                          </label>
                          <label
                            className="aerograf-cu-presiune-in-cupa"
                            htmlFor="aerograf-cu-presiune-in-cupa"
                          >
                            Aerograf cu presiune în cupă{" "}
                            <input
                              id="aerograf-cu-presiune-in-cupa"
                              className="subcategory"
                              type="checkbox"
                              value="aerograf-cu-presiune-in-cupa"
                            />
                          </label>
                        </fieldset>
                      </div>
                    </div>
                  )}
                </label>
                <label className="ecprotectie" htmlFor="ecprotectie">
                  Echipamente de protecție{" "}
                  <input
                    id="ecprotectie"
                    className="category"
                    type="checkbox"
                    value="echipamente-de-protectie"
                    onClick={handleOnClickSubcateg}
                  />
                  {showSubcateg === "echipamente-de-protectie" && (
                    <div className="subcateg__container">
                      <div className="item">
                        <fieldset>
                          <label className="manusi" htmlFor="manusi">
                            Manusi{" "}
                            <input
                              id="manusi"
                              className="subcategory"
                              type="checkbox"
                              value="manusi"
                            />
                          </label>
                          <label
                            className="masca-protectie"
                            htmlFor="masca-protectie"
                          >
                            Masca protectie{" "}
                            <input
                              id="masca-protectie"
                              className="subcategory"
                              type="checkbox"
                              value="masca-protectie"
                            />
                          </label>
                          <label
                            className="ochelari-protectie"
                            htmlFor="ochelari-protectie"
                          >
                            Ochelari protectie{" "}
                            <input
                              id="ochelari-protectie"
                              className="subcategory"
                              type="checkbox"
                              value="ochelari-protectie"
                            />
                          </label>
                        </fieldset>
                      </div>
                    </div>
                  )}
                </label>
              </div>
            </fieldset>
            <h4 className="available">ÎN STOC</h4>
            <select id="selectAvailable" className="select-available">
              <option value="true">DA</option>
              <option value="false">NU</option>
            </select>
            <Input
              id="price"
              element="input"
              type="number"
              label="PRET CU TVA"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Introduceti pretul actual"
              onInput={inputHandler}
            />
            <Input
              id="color"
              element="input"
              type="text"
              label="CULOARE"
              validators={[]}
              errorText="Introduceti culoarea"
              onInput={inputHandler}
            />
            <Input
              id="dimensions"
              element="input"
              type="text"
              label="DIMENSIUNI"
              validators={[]}
              errorText="Introduceti dimensiunea"
              onInput={inputHandler}
            />
            <Input
              id="brand"
              element="input"
              type="text"
              label="BRAND"
              validators={[]}
              errorText="Introduceti brandul"
              onInput={inputHandler}
            />
            <Input
              id="material"
              element="input"
              type="text"
              label="MATERIAL"
              validators={[]}
              errorText="Introduceti materialul"
              onInput={inputHandler}
            />
            <Input
              id="quantity"
              element="input"
              type="text"
              label="CANTITATE"
              validators={[]}
              errorText="Introduceti cantitatea"
              onInput={inputHandler}
            />
          </div>

          <div className="add-menu__actions">
            {/* disabled={!formState.isValid} */}
            <Button id="btnSubmit" type="submit">
              SALVEAZA
            </Button>

            <Button id="btnCancel" type="button" to={"/admin/managemenus"}>
              ANULEAZA
            </Button>
          </div>
        </Form>
      </CSSTransition>
    </React.Fragment>
  );
};

export default AddMenu;
