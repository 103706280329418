import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import MenuListItems from "../components/MenusList";

import DocumentMeta from 'react-document-meta';

import "./CategChoose.css";

const MenuList = () => {

  const [loadedMenus, setLoadedMenus] = useState();

  const [serachItem, setSearchItem] = useState("");

  const [filteredItemsState, setFilteredItemsState] = useState([]);

  useEffect(() => {
    setFilteredItemsState(loadedMenus
      ? loadedMenus.filter((item) =>
        item.name
          .trim()
          .toLowerCase()
          .includes(
            serachItem.trim() !== ""
              ? serachItem.trim().toString().toLowerCase()
              : null
          )
      )
      : "");
  }, [serachItem, loadedMenus])


  const handleChangeSearchItem = (event) => {
    setSearchItem(event.target.value);
  };

  useEffect(() => {
    const sendRequest = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_DOMAIN}/v1/menus`
        );

        const responseData = await response.json();

        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }

        setLoadedMenus(responseData.data);
      } catch (err) {
        console.log('categ choose: ', err.message);
      }
    };
    sendRequest();
  }, []);

  const meta = {
    title: 'Tecnifer',
    description: 'Tecnifer srl comercializeaza ornamente pentru mobilier, menghine tâmplărie, adezivi, chituri și soluții pentru tratara lemnului de o calitate superioară. Magazinul nostru situat in Oradea comercializează de 20 de ani produse de cea mai bună calitate importate din Italia'
  };

  return (
    <DocumentMeta {...meta}>
      <React.Fragment>

        <div className="subheader">
          <input
            placeholder="Caută un produs"
            className="input__search"
            type="text"
            value={serachItem}
            onChange={handleChangeSearchItem}
          />
          <span className="span__search">
            <i className="fas fa-search"></i>
          </span>
        </div>
        {filteredItemsState.length > 0 && (
          <MenuListItems items={filteredItemsState} /* menutype={serachItem} */ />
        )}
        {filteredItemsState.length === 0 && (
          <div className="content">
            <div className="grid container">
              <Link to={`/accesorii-mobila`}>
                <div className="item">
                  <div className="item-h4-container">
                    <h4>Accesorii mobilă</h4>
                  </div>
                  <img src={require("../img/accesorii-mobila.webp").default} alt="" />
                </div>
              </Link>

              <Link to={`/accesorii-tapiterie`}>
                <div className="item">
                  <div className="item-h4-container">
                    <h4>Accesorii tapițerie</h4>
                  </div>
                  <img src={require("../img/accesorii-tapiterie.webp").default} alt="" />
                </div>
              </Link>

              <Link to={`/ornamente-mobila-lemn`}>
                <div className="item">
                  <div className="item-h4-container">
                    <h4>Ornamente mobila lemn</h4>
                  </div>
                  <img src={require("../img/ornamente-mobila-lemn.webp").default} alt="" />
                </div>
              </Link>

              <Link to={`/menghine-tamplar`}>
                <div className="item">
                  <div className="item-h4-container">
                    <h4>Menghine tâmplar</h4>
                  </div>
                  <img src={require("../img/menghine-tamplar.webp").default} alt="" />
                </div>
              </Link>

              <Link to={`/adezivi-si-chituri-pentru-lemn`}>
                <div className="item">
                  <div className="item-h4-container">
                    <h4>Adezivi și chituri lemn</h4>
                  </div>
                  <img src={require("../img/adezivi-si-chituri-pentru-lemn.webp").default} alt="" />
                </div>
              </Link>

              <Link to={`/solutii-pentru-tratarea-lemnului`}>
                <div className="item">
                  <div className="item-h4-container">
                    <h4>Soluții tratare suprafețe</h4>
                  </div>
                  <img src={require("../img/solutii-pentru-tratarea-lemnului.webp").default} alt="" />
                </div>
              </Link>

              <Link to={`/cuie-capse-si-agrafe`}>
                <div className="item">
                  <div className="item-h4-container">
                    <h4>Cuie capse și agrafe</h4>
                  </div>
                  <img src={require("../img/cuie-capse-si-agrafe.webp").default} alt="" />
                </div>
              </Link>

              <Link to={`/abrazive-pentru-lemn`}>
                <div className="item">
                  <div className="item-h4-container">
                    <h4>Abrazive lemn</h4>
                  </div>
                  <img src={require("../img/abrazive-pentru-lemn.webp").default} alt="" />
                </div>
              </Link>

              <Link to={`/pistoale-pneumatice-si-vopsitorie`}>
                <div className="item">
                  <div className="item-h4-container">
                    <h4>Pneumatice și vopsitorie</h4>
                  </div>
                  <img src={require("../img/pistoale-pneumatice-si-vopsitorie.webp").default} alt="" />
                </div>
              </Link>

              <Link to={`/echipamente-de-protectie`}>
                <div className="item">
                  <div className="item-h4-container">
                    <h4>Echipamente de protecție</h4>
                  </div>
                  <img src={require("../img/echipamente-de-protectie.webp").default} alt="" />
                </div>
              </Link>
            </div>
          </div>
        )}
      </React.Fragment>
    </DocumentMeta>
  );
};

export default MenuList;
