import React from "react";

import { CSSTransition } from "react-transition-group";

import Button from "../../shared/components/FormElements/Button";

import "./About.css";

const AboutPage = (props) => {
  return (
    <React.Fragment>
      <CSSTransition
        classNames={{
          appear: "slide-in-left-enter",
          appearActive: "slide-in-left-enter-active",
        }}
        timeout={1000}
        in
        appear
        unmountOnExit
      >
        <div className="about">
          <div className="item-box">
            <h1 className="item text-center m">ORAR</h1>

            <ul className="about-list">
              <div className="about-hours">
                <li className="item">
                  <div className="about-item__info">
                    <span className="about-day">LUNI</span>

                    <span className="dots link"></span>

                    <span className="about-time">8:00 AM - 4:00 PM</span>
                  </div>
                </li>

                <li className="item">
                  <div className="about-item__info">
                    <span className="about-day">MARTI</span>

                    <span className="dots link"></span>

                    <span className="about-time">8:00 AM - 4:00 PM</span>
                  </div>
                </li>

                <li className="item">
                  <div className="about-item__info">
                    <span className="about-day">MIERCURI</span>

                    <span className="dots link"></span>

                    <span className="about-time">8:00 AM - 4:00 PM</span>
                  </div>
                </li>

                <li className="item">
                  <div className="about-item__info">
                    <span className="about-day">JOI</span>

                    <span className="dots link"></span>

                    <span className="about-time">8:00 AM - 4:00 PM</span>
                  </div>
                </li>

                <li className="item">
                  <div className="about-item__info">
                    <span className="about-day">VINERI</span>

                    <span className="dots link"></span>

                    <span className="about-time">8:00 AM - 4:00 PM</span>
                  </div>
                </li>

                <li className="item">
                  <div className="about-item__info">
                    <span className="about-day">SATURDAY</span>

                    <span className="dots link"></span>

                    <span className="about-time">ÎNCHIS</span>
                  </div>
                </li>
                <li className="item">
                  <div className="about-item__info">
                    <div className="about-day">DUMINICA</div>

                    <div className="about-time">ÎNCHIS</div>
                  </div>
                </li>
              </div>

              <h1 className="text-center item">NE GĂSEȘTI AICI</h1>

              <p className="text-center text-white">
                Str. Clujului nr 213, Oradea
              </p>

              <p className="text-center text-white">0740 685 750</p>

              <p className="text-center text-white">
                robertotecnifer@gmail.com
              </p>

              <h1 className="text-center">
                <Button>
                  <a
                    className="text-white "
                    href="https://www.google.com/maps/place/Calea+Clujului+213,+Oradea/@47.037212,21.9812457,17z/data=!3m1!4b1!4m5!3m4!1s0x47463839136d6ae9:0x7742eaae4ff235e0!8m2!3d47.037212!4d21.9834344"
                  >
                    VEZI LOCATIA
                  </a>
                </Button>
              </h1>
            </ul>
          </div>
        </div>
      </CSSTransition>
    </React.Fragment>
  );
};

export default AboutPage;
