import React, { useState, useContext, useEffect } from "react";

import History from "../components/History";

import { AuthContext } from "../../shared/components/Context/auth-context";

import ErrorModal from "../../shared/components/UIElements/ErrorModal";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

const Orders = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState();

  const [loadedItems, setLoadedItems] = useState([]);

  const[userData, setUserData] = useState();

  const auth = useContext(AuthContext);

  useEffect(() => {
    setIsLoading(true);

    const sendRequest = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_DOMAIN}/v1/order/${auth.userId}/${auth.userRole}`
        );

        const responseData = await response.json();

        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }

        setLoadedItems(responseData.data);
        setUserData(responseData.user);
      } catch (err) {
        setError(err.message);
      }

      setIsLoading(false);
    };

    sendRequest();

  }, [auth.userId,auth.userRole]);

  const errorHandler = () => {
    setError(null);
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={errorHandler} />

      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}

      {!isLoading && loadedItems && (
        <div className="content">
          <History itemsOrder={loadedItems} userData={userData} />
        </div>
      )}
    </React.Fragment>
  );
};

export default Orders;
