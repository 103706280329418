import React, {
  useState,
  useCallback,
  useReducer,
  useContext,
  useEffect,
} from "react";

import { useHistory } from "react-router-dom";

import { CSSTransition } from "react-transition-group";

import { AuthContext } from "../../shared/components/Context/auth-context";

import { CartContext } from "../../shared/components/Context/cart-context";

import Button from "../../shared/components/FormElements/Button";

import Modal from "../../shared/components/UIElements/Modal";

import ErrorModal from "../../shared/components/UIElements/ErrorModal";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import Input from "../../shared/components/FormElements/Input";

import { VALIDATOR_REQUIRE } from "../../shared/util/validators";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import "./MenuSelected.css";

import ProductReviewItem from "../../reviews/components/ProductReviewItem";
import "../../reviews/components/ProductReviewItem.css";

/* import MetaTags from "react-meta-tags"; */
import { Helmet } from "react-helmet";

const formReducer = (state, action) => {
  switch (action.type) {
    case "INPUT_CHANGE":
      let formIsValid = true;

      for (const inputId in state.inputs) {
        if (inputId === action.inputId) {
          formIsValid = formIsValid && action.isValid;
        } else {
          formIsValid = formIsValid && state.inputs[inputId].isValid;
        }
      }

      return {
        ...state,

        inputs: {
          ...state.inputs,

          [action.inputId]: { value: action.value, isValid: action.isValid },
        },

        isValid: formIsValid,
      };

    default:
      return state;
  }
};

const MenuSelected = (props) => {
  const [isLoadingMenuReviews, setIsLoadingMenuReviews] = useState(false);

  const [loadedMenuReviews, setLoadedMenuReviews] = useState();

  const [addedItemFlag, setAddedItemFlag] = useState(false);

  const [checkItemExistInCart, setCheckItemExistInCart] = useState(false);

  const auth = useContext(AuthContext);

  const cartContext = useContext(CartContext);

  let inputValue;

  let reviewRating;

  if (props.item1) {
    inputValue = props.item1.text;
  } else {
    inputValue = "";
  }

  const [formState, dispatch] = useReducer(formReducer, {
    inputs: {
      review: {
        value: inputValue,

        isValid: false,
      },
    },

    isValid: false,
  });

  const addItemToCart = (cartId) => {
    const sendRequest = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_DOMAIN}/v1/cart/${cartId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },

            body: JSON.stringify({
              menuId: cartId,

              name: props.item[0].name,

              quantity: 1,

              price: props.item[0].price,

              userId: auth.userIDD ? auth.userIDD : auth.userId,
            }),
          }
        );

        const responseData = await response.json();

        cartContext.saveCartItems([
          ...cartContext.cartItems,
          responseData.data,
        ]);

        if (!auth.userIDD) {
          auth.setUserIDD(responseData.userIDD);
        }

        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }
      } catch (err) {
        //setError(err.message);
      }
    };

    sendRequest();

    cartContext.saveCountItemsCart(cartContext.countItemsCart + 1);

    setAddedItemFlag(true);
  };

  useEffect(() => {
    setIsLoadingMenuReviews(true);
    const sendRequest = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_DOMAIN}/v1/reviews/menuid/` +
          props.item[0]._id
        );
        const responseData = await response.json();

        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }

        setLoadedMenuReviews(responseData.data);
      } catch (err) {
        setError(err.message);
      }
      setIsLoadingMenuReviews(false);
    };

    sendRequest();
  }, [auth.userId, props.item]);

  //used to change button 'Add to cart' into 'Already added to the Cart. Use the Cart to complete the order, or add another product'
  useEffect(() => {
    let result = "";

    if (cartContext.cartItems) {
      result = cartContext.cartItems.filter(
        (cartItem) => cartItem.menuId === props.item[0]._id
      );

      if (result.length > 0) setCheckItemExistInCart(true);
    }
  }, [auth.cartItems, props.item, cartContext.cartItems]);

  const poorRating = () => {
    document.getElementById("ratingText").value = "Foarte slab";

    document.getElementById("ratingText").style = "color: black";

    reviewRating = 1;
  };

  const fairRating = () => {
    document.getElementById("ratingText").value = "Slab";

    document.getElementById("ratingText").style = "color: black";

    reviewRating = 2;
  };

  const goodRating = () => {
    document.getElementById("ratingText").value = "Bun";

    document.getElementById("ratingText").style = "color: black";

    reviewRating = 3;
  };

  const veryGoodRating = () => {
    document.getElementById("ratingText").value = "Foarte Bun";

    document.getElementById("ratingText").style = "color: black";

    reviewRating = 4;
  };

  const excellentRating = () => {
    document.getElementById("ratingText").value = "Excelent";

    document.getElementById("ratingText").style = "color: black";

    reviewRating = 5;
  };

  const userRating = () => {
    document.getElementById("ratingText").value = "Select Menu Rating";

    document.getElementById("ratingText").style = "color: red";
  };

  const [showNewReview, setShowNewReview] = useState(false);

  const [showSuccess, setShowSuccess] = useState(false);

  const [showEditSuccess, setEditSuccess] = useState(false);

  const [showDeleteSuccess, setDeleteSuccess] = useState(false);

  const openShowSuccesHandler = () => setShowSuccess(true);

  const openShowEditSuccesHandler = () => setEditSuccess(true);

  const openShowDeleteSuccesHandler = () => setDeleteSuccess(true);

  const openNewReviewHandler = () => setShowNewReview(true);

  const closeNewReviewHandler = () => setShowNewReview(false);

  const inputHandler = useCallback(
    (id, value, isValid) => {
      dispatch({
        type: "INPUT_CHANGE",

        value: value,

        isValid: isValid,

        inputId: id,
      });
    },

    [dispatch]
  );

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState();

  const history = useHistory();

  const reviewDeleteHandler = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_DOMAIN}/v1/reviews/${props.item1._id}`,
        {
          method: "DELETE",

          headers: {
            Authorization: "Bearer " + auth.token,
          },
        }
      );

      const responseData = await response.json();

      // Throw error

      if (!response.ok) {
        throw new Error(responseData.error);
      }

      setIsLoading(false);

      openShowDeleteSuccesHandler(true);
    } catch (err) {
      console.log(err);

      setIsLoading(false);
    }
  };

  const reviewSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);

      const formData = new FormData();

      formData.append("text", formState.inputs.review.value);

      formData.append("rating", reviewRating);

      formData.append("menu", props.item[0]._id);

      formData.append("user", auth.userId);

      const response = await fetch(
        `${process.env.REACT_APP_API_DOMAIN}/v1/menus/${props.item[0]._id}/${auth.userId}/review`,
        {
          method: "POST",

          headers: {
            Authorization: "Bearer " + auth.token,
          },

          body: formData,
        }
      );

      const responseData = await response.json();

      // Throw error

      if (!response.ok) {
        throw new Error(responseData.error);
      }

      setIsLoading(false);

      openShowSuccesHandler(true);
    } catch (err) {
      if (err.message === "Duplicate field value entered") {
        setError("Reviw for this menu is already exist");
      } else {
        setError("Something went wrong please try again");
      }

      setIsLoading(false);
    }
  };

  const reviewEditHandler = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);

      const formData = new FormData();

      formData.append("text", formState.inputs.review.value);

      formData.append("rating", reviewRating);

      formData.append("menu", props.item[0]._id);

      formData.append("user", auth.userId);

      const response = await fetch(
        `${process.env.REACT_APP_API_DOMAIN}/v1/reviews/${props.item1._id}`,
        {
          method: "PUT",
          body: formData,

          headers: {
            Authorization: "Bearer " + auth.token,
          },
        }
      );

      const responseData = await response.json();

      // Throw error
      if (!response.ok) {
        throw new Error(responseData.error);
      }

      setIsLoading(false);

      openShowEditSuccesHandler(true);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const errorHandler = () => {
    setError(null);
  };
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{props.item[0].name}</title>
        <meta name="description" content={props.item[0].description} />
        <script type="application/ld+json">
          {`{
          "@context": "https://schema.org/",
          "@type": "Product",
          ${loadedMenuReviews && loadedMenuReviews.length > 0
              ? `"aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "${loadedMenuReviews &&
              loadedMenuReviews.map((review) => {
                let rating;
                if (review.menu._id === props.item[0]._id) {
                  rating = review.rating;
                }
                return rating;
              })
              }",
            "reviewCount": "${loadedMenuReviews && loadedMenuReviews.length}"
          },`
              : ""
            }
          "name": "${props.item[0].name}",          
          "material" : "${props.item[0].material ? props.item[0].material : ""
            }",
          "brand": "${props.item[0].brand ? props.item[0].brand : ""}",
          "color": "${props.item[0].color ? props.item[0].color : ""}",       
          "image": [
            "https://tecnifer.ro/backend/${props.item[0].imageMain}",
            "https://tecnifer.ro/backend/${props.item[0].image0}",
            "https://tecnifer.ro/backend/${props.item[0].image1}"
          ],
          "description": "${props.item[0].description}",
          "offers": {
            "@type": "Offer",        
            "priceCurrency": "RON",
            "price": ${props.item[0].price},
            "availability": "${props.item[0].available === true
              ? "http://schema.org/InStock"
              : "http://schema.org/OutOfStock"
            }",
            "seller": {
              "@type": "Organization",
              "name": "SC Tecnifer SRL"
            }
          }
        }`}
        </script>
      </Helmet>
      <Modal
        show={showDeleteSuccess}
        // onCancel={closeShowSuccessHandler}

        header={"Success"}
        contentClass="menu-selected__modal-content"
        footerClass="menu-selected__modal-actions"
      >
        <p>Review successfully deleted!!!</p>

        <footer>
          <Button className="btn" onClick={history.go}>
            Ok
          </Button>
        </footer>
      </Modal>

      <Modal
        show={showEditSuccess}
        // onCancel={closeShowSuccessHandler}

        header={"Success"}
        contentClass="menu-selected__modal-content"
        footerClass="menu-selected__modal-actions"
      >
        <p>Review modificat cu succes!!!</p>

        <footer>
          <Button className="btn" onClick={history.go}>
            Ok
          </Button>
        </footer>
      </Modal>

      <Modal
        show={showSuccess}
        // onCancel={closeShowSuccessHandler}
        header={"Success"}
        contentClass="menu-selected__modal-content"
        footerClass="menu-selected__modal-actions"
      >
        <p>Review adaugat cu succes!!!</p>

        <footer>
          <Button className="btn" onClick={history.go}>
            Ok
          </Button>
        </footer>
      </Modal>

      {isLoading && <LoadingSpinner asOverlay />}

      <Modal
        show={showNewReview}
        onCancel={closeNewReviewHandler}
        header={props.item[0].name}
        contentClass="menu-selected__modal-content"
        footerClass="menu-selected__modal-actions"
      >
        <div onClick={userRating}>
          {props.item1 && (
            <Input
              id="review"
              element="textarea"
              type="text"
              label="SCRIE PAREREA TA"
              value={props.item1.text}
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Te rog scrie parerea ta"
              onInput={inputHandler}
            />
          )}

          {!props.item1 && (
            <Input
              id="review"
              element="textarea"
              type="text"
              label="SCRIE PAREREA TA"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Te rog scrie parerea ta"
              onInput={inputHandler}
            />
          )}
        </div>

        <div className="rating">
          <button type="button" onClick={excellentRating}>
            ☆
          </button>

          <button type="button" onClick={veryGoodRating}>
            ☆
          </button>

          <button type="button" onClick={goodRating}>
            ☆
          </button>

          <button type="button" onClick={fairRating}>
            ☆
          </button>

          <button type="button" onClick={poorRating}>
            ☆
          </button>
        </div>

        {props.item1 && (
          <div className="rating">
            {props.item1.rating === 5 && (
              <input
                type="text"
                value="Excellent"
                id="ratingText"
                className="select-rating__input"
                disabled
              />
            )}

            {props.item1.rating === 4 && (
              <input
                type="text"
                value="Very Good"
                id="ratingText"
                className="select-rating__input"
                disabled
              />
            )}

            {props.item1.rating === 3 && (
              <input
                type="text"
                value="Good"
                id="ratingText"
                className="select-rating__input"
                disabled
              />
            )}

            {props.item1.rating === 2 && (
              <input
                type="text"
                value="Poor"
                id="ratingText"
                className="select-rating__input"
                disabled
              />
            )}

            {props.item1.rating === 1 && (
              <input
                type="text"
                value="Fair"
                id="ratingText"
                className="select-rating__input"
                disabled
              />
            )}
          </div>
        )}

        <div>
          {!props.item1 && (
            <input
              type="text"
              value="SELECTEAZA UN RATING"
              id="ratingText"
              className="select-rating__input"
              disabled
            />
          )}

          {props.item1 && (
            <input
              type="text"
              id="ratingText"
              className="select-rating__input"
              disabled
            />
          )}
        </div>

        <div className="review-buttons">
          {!props.item1 && (
            <Button
              id="btnSubmit"
              type="button"
              onClick={reviewSubmitHandler}
              disabled={!formState.isValid}
            >
              SALVEAZA
            </Button>
          )}

          {props.item1 && (
            <Button
              id="btnUpdate"
              type="button"
              onClick={reviewEditHandler}
              disabled={!formState.isValid}
            >
              MODIFICA
            </Button>
          )}

          {props.item1 && (
            <Button
              id="btnDelete"
              type="button"
              onClick={reviewDeleteHandler}
              disabled={false}
            >
              STERGE
            </Button>
          )}

          <Button onClick={closeNewReviewHandler}>ÎNCHIDE</Button>
        </div>
      </Modal>

      <ErrorModal error={error} onClear={errorHandler} />

      <CSSTransition
        classNames={{
          appear: "slide-in-left-enter",

          appearActive: "slide-in-left-enter-active",
        }}
        timeout={1000}
        in
        appear
        unmountOnExit
      >
        <div className="menu-selected__content">
          <div className="menu-selected__rating">
            <p className="menu-avg-rating">
              {props.item[0].averageRating === 5 ? (
                <div className="star-icon">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                </div>
              ) : props.item[0].averageRating === 4 ? (
                <div className="star-icon">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                </div>
              ) : props.item[0].averageRating === 3 ? (
                <div className="star-icon">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                </div>
              ) : props.item[0].averageRating === 2 ? (
                <div className="star-icon">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                </div>
              ) : props.item[0].averageRating === 1 ? (
                <span>
                  <i className="fas fa-star"></i>
                </span>
              ) : (
                          <span></span>
                        )}
            </p>
            {props.item[0].averageRating !== undefined && (
              <p className="reviews-nr-users-top">
                (
                {!isLoadingMenuReviews &&
                  loadedMenuReviews &&
                  loadedMenuReviews.length}
                )
              </p>
            )}
          </div>
          <div className="menu-selected__image">
            <Carousel showThumbs={false} showStatus={false}>
              <div>
                <img
                  src={`https://tecnifer.ro/backend/${props.item[0].imageMain}`}
                  alt={props.item[0].name + "_1"}
                />
              </div>
              <div>
                <img
                  src={`https://tecnifer.ro/backend/${props.item[0].image0}`}
                  alt={props.item[0].name + "_2"}
                />
              </div>
              <div>
                <img
                  src={`https://tecnifer.ro/backend/${props.item[0].image1}`}
                  alt={props.item[0].name + "_3"}
                />
              </div>
            </Carousel>
          </div>
          <div className="menu-selected__allinfo">
            <div className="menu-selected__info">
              <h1 className="menu-selected__itemName">{props.item[0].name}</h1>
              <p className="menu-selected__itemDesc">
                {props.item[0].description}
              </p>
              <div className="menu-selected__itemsFromFamillyConainer">
                <div className="menu-selected__itemsFromFamillyHeader">

                </div>
                <div className="menu-selected__itemsFromFamillyContent">

                </div>
              </div>
              <div className="menu-selected__itemSpecContainer">
                <div className="menu-selected__itemSpecHeader">
                  Specificatii
                </div>
                <div className="menu-selected__itemSpecContent">
                  {props.item[0].brand !== "" &&
                    props.item[0].brand !== undefined && (
                      <div className="menu-selected__itemSpec">
                        <div className="menu-selected__itemSpecHead">
                          Brand:
                        </div>
                        <div>{props.item[0].brand}</div>
                      </div>
                    )}
                  {props.item[0].color !== "" &&
                    props.item[0].color !== undefined && (
                      <div className="menu-selected__itemSpec">
                        <div className="menu-selected__itemSpecHead">
                          Culoare:{" "}
                        </div>
                        <div>{props.item[0].color}</div>
                      </div>
                    )}
                  {props.item[0].dimensions !== "" &&
                    props.item[0].dimensions !== undefined && (
                      <div className="menu-selected__itemSpec">
                        <div className="menu-selected__itemSpecHead">
                          Dimensiuni:{" "}
                        </div>
                        <div>{props.item[0].dimensions}</div>
                      </div>
                    )}
                  {props.item[0].material !== "" &&
                    props.item[0].material !== undefined && (
                      <div className="menu-selected__itemSpec">
                        <div className="menu-selected__itemSpecHead">
                          Material:{" "}
                        </div>
                        <div>{props.item[0].material}</div>
                      </div>
                    )}
                  {props.item[0].quantity !== "" &&
                    props.item[0].quantity !== undefined && (
                      <div className="menu-selected__itemSpec">
                        <div className="menu-selected__itemSpecHead">
                          Cantitate:{" "}
                        </div>
                        <div>{props.item[0].quantity}</div>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="menu-selected__price">
              <h2 className="menu-price">{props.item[0].price} Lei</h2>
            </div>
            {/* <div className="menu-selected__priceold">
              <p className="menu-price-old">{props.item[0].priceOld} Lei</p>
            </div>
            <div className="menu-selected__priceearning">
              <p className="menu-price-earning">
                Ai o reducere de {(props.item[0].priceOld - props.item[0].price).toFixed(2)} Lei
              </p>
            </div> */}
            <div className="menu-selected__tva">
              <p className="menu-price-tva">TVA inclus</p>
            </div>
          </div>

          <div className="menu-selected__actions">
            <div className="cnt-btn-add-to-cart">
              {checkItemExistInCart ? (
                <Button className="btn-add-to-cart" disabled>
                  <span style={{ color: "green" }}>
                    Produsul este deja adăugat în coș
                  </span>
                </Button>
              ) : (
                  <Button
                    className="btn-add-to-cart"
                    disabled={addedItemFlag}
                    onClick={addItemToCart.bind(this, props.item[0]._id)}
                  >
                    <span className="add-to-cart">
                      <i className="fas fa-cart-plus"></i>
                    </span>
                    <span className="add-to-cart-text">Adaugă în coș</span>
                  </Button>
                )}
            </div>
            {auth.isLoggedIn && (
              <div className="cnt-btn-write-review">
                <Button
                  className="btn-write-review"
                  onClick={openNewReviewHandler}
                >
                  <span className="write-review">
                    <i className="fas fa-pencil-alt"></i>
                  </span>
                  <span className="write-review-text">Scrie un review</span>
                </Button>
              </div>
            )}

            <div className="cnt-btn-back-to-list">
              <Button className="btn-back-to-list" onClick={history.goBack}>
                <span className="back-to-list">
                  <i className="fas fa-long-arrow-alt-left"></i>
                </span>
                <span className="back-to-list-text">
                  {props.item[0].category[0].replace(/-/g, " ")}
                </span>
              </Button>
            </div>
          </div>

          {props.item[0].averageRating !== undefined ? (
            <div className="menu-selected__reviews">
              {/* header */}
              <div className="reviews-header">
                {/* left */}
                <div className="reviews-left cnt-rev">
                  <p className="reviews-first-text">Review-uri</p>
                </div>
                {/* right */}
                <div className="reviews-right cnt-rev">
                  <p className="review-avg-rating">
                    {props.item[0].averageRating === 5 ? (
                      <div className="star-icon">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                      </div>
                    ) : props.item[0].averageRating === 4 ? (
                      <div className="star-icon">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                      </div>
                    ) : props.item[0].averageRating === 3 ? (
                      <div className="star-icon">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                      </div>
                    ) : props.item[0].averageRating === 2 ? (
                      <div className="star-icon">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                      </div>
                    ) : props.item[0].averageRating === 1 ? (
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                    ) : (
                                <p></p>
                              )}
                  </p>
                  <p className="reviews-nr-users">
                    (
                    {!isLoadingMenuReviews &&
                      loadedMenuReviews &&
                      loadedMenuReviews.length}
                    )
                  </p>
                </div>
              </div>
              {/* content */}
              <div className="reviews-content">
                <ul className="menu-info">
                  {!isLoadingMenuReviews &&
                    loadedMenuReviews &&
                    loadedMenuReviews.map((review) => (
                      <ProductReviewItem
                        key={review._id}
                        userName={review.user.name}
                        menuId={review.menu._id}
                        menuName={review.menu.name}
                        reviewText={review.text}
                        reviewRating={review.rating}
                        reviewDate={review.createdAt}
                      />
                    ))}
                </ul>
              </div>
            </div>
          ) : (
              <div className="reviews-header">
                <div className="reviews-no-found">
                  <p>Niciun review scris pentru acest produs</p>
                  <p>Scrie un review ca si ceilalti sa stie</p>
                </div>
              </div>
            )}
        </div>
      </CSSTransition>
    </React.Fragment>
  );
};

export default MenuSelected;
