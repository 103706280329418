import { useState, useCallback, useEffect } from "react";

export const useCart = () => {
  const [countItemsCart, setCountItemsCart] = useState(0);

  const [cartItems, setCartItems] = useState([]);

  const saveCountItemsCart = useCallback(
    (count) => {
      setCountItemsCart(count);

      localStorage.setItem(
        "cartCount",

        JSON.stringify({
          countItems: count,
        })
      );
    },
    []
  );

  const saveCartItems = useCallback(
    (items) => {
      setCartItems(items);

      localStorage.setItem(
        "cartData",

        JSON.stringify({
          cartItems: items,
        })
      );
    },

    []
  );

  useEffect(() => {
    const storedDataCount = JSON.parse(localStorage.getItem("cartCount"));

    const storedDataItems = JSON.parse(localStorage.getItem("cartData"));

    if (
      storedDataCount &&
      storedDataItems &&
      storedDataCount.countItems &&
      storedDataItems.cartItems
    ) {
      saveCountItemsCart(storedDataCount.countItems);

      saveCartItems(storedDataItems.cartItems);
    }
  }, [saveCountItemsCart, saveCartItems]);

  return { saveCountItemsCart, saveCartItems, countItemsCart, cartItems };
};
