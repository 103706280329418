import React, { useState, useContext, useEffect } from "react";

import { useHistory } from "react-router-dom";

import { CSSTransition } from "react-transition-group";

import Button from "../../shared/components/FormElements/Button";

import Modal from "../../shared/components/UIElements/Modal";

import Form from "../../shared/components/UIElements/Form";

import Input from "../../shared/components/FormElements/Input";

import ErrorModal from "../../shared/components/UIElements/ErrorModal";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

//import ImageUpload from "../../shared/components/FormElements/ImageUpload";

import { AuthContext } from "../../shared/components/Context/auth-context";

import { VALIDATOR_REQUIRE } from "../../shared/util/validators";

import { useForm } from "../../shared/hooks/form-hooks";

import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";

import ImagePreview from "./ImagePreview";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import "./EditMenu.css";

const EditMenu = (props) => {
  const auth = useContext(AuthContext);
  const [showSubcategEdit, setShowSubcategEdit] = useState("");
  const [dataImg, setDataImg] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [isCarouselOff, setIsCarouselOff] = useState(true);
  const [isCameraOn, setIsCameraOn] = useState(true);

  const [formState, inputHandler] = useForm(
    {
      name: {
        value: props.items.name,

        isValid: true,
      },

      description: {
        value: props.items.description,

        isValid: true,
      },

      price: {
        value: props.items.price,

        isValid: true,
      },
      
      color: {
        value: props.items.color,

        isValid: true,
      },

      brand: {
        value: props.items.brand,

        isValid: true,
      },

      dimensions: {
        value: props.items.dimensions,

        isValid: true,
      },

      quantity: {
        value: props.items.quantity,

        isValid: true,
      },

      material: {
        value: props.items.material,

        isValid: true,
      },
    },

    true
  );

  const { isLoading } = useState(false);

  const [error, setError] = useState();

  const menuId = props.items._id;

  const history = useHistory();

  const menuSubmitHandler = async (event) => {
    event.preventDefault();

    // Select option availability
    const e = document.getElementById("selectAvailable");

    const available = e.options[e.selectedIndex].value;

    //Checkbox category
    let category = document.getElementsByClassName("category");

    const checkboxesChecked = [];

    for (let i = 0; i < category.length; i++) {
      // And stick the checked ones onto an array...

      if (category[i].checked === true) {
        checkboxesChecked.push(category[i].value);
      }
    }

    //Checkbox subcategory
    let subcategory = document.getElementsByClassName("subcategory");

    const checkboxesCheckedSub = [];

    for (let i = 0; i < subcategory.length; i++) {
      // And stick the checked ones onto an array...
      if (subcategory[i].checked === true) {
        checkboxesCheckedSub.push(subcategory[i].value);
      }
    }

    if (checkboxesChecked.length !== 0 && checkboxesCheckedSub.length !== 0) {
      try {
        const formData = new FormData();

        formData.append("name", formState.inputs.name.value);
        formData.append("description", formState.inputs.description.value);
        formData.append("price", formState.inputs.price.value);        
        formData.append("color", formState.inputs.color.value);
        formData.append("brand", formState.inputs.brand.value);
        formData.append("dimensions", formState.inputs.dimensions.value);
        formData.append("material", formState.inputs.material.value);
        formData.append("quantity", formState.inputs.quantity.value);
        formData.append("available", available);
        formData.append("category", checkboxesChecked);
        formData.append("subcategory", checkboxesCheckedSub);

        if (fileData.length > 0) {
          for (var i = 0; i < fileData.length; i++) {
            var file = fileData[i];
            if (i === 0) {
              formData.append(
                "imageMain",
                file,
                formState.inputs.name.value + "_1"
              );
            } else {
              formData.append(
                "image" + (i - 1),
                file,
                formState.inputs.name.value + "_" + (i + 1)
              );
            }
          }
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_DOMAIN}/v1/menus/edit/${menuId}`,
          {
            method: "PUT",
            body: formData,

            headers: {
              Authorization: "Bearer " + auth.token,
            },
          }
        );

        const responseData = await response.json();

        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }

        openShowSuccesHandler(true);
      } catch (err) {
        console.log(err);

        if (err.message === "Campul introdus este duplicat") {
          setError("Numele produsului deja exista");
        } else {
          setError("A aparut o eroare la editare te rugam sa incerci din nou");
        }
      }
    } else {
      setError("Selecteaza o categorie si o subcategorie");
    }
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const errorHandler = () => {
    setError(null);
  };

  let arrCategory = props.items.category;

  let available = props.items.available;

  //let imageUrl = props.items.image;

  let accesoriiMobilaIschecked,
    accesoriiTapiterieIschecked,
    ornamenteMobilaLemnIschecked,
    menghineTamplarIschecked,
    adeziviSiChituriPentruLemnIschecked,
    solutiiPentruTratareaLemnuluiIschecked,
    cuieCapseSiAgrafeIschecked,
    abrazivePentruLemnIschecked,
    pistoalePneumaticeSiVopsitorieIschecked,
    echipamenteDeProtectieIschecked,
    isAvailable;

  arrCategory.find((c) => {
    if (c === "accesorii-mobila") {
      accesoriiMobilaIschecked = true;
    }

    if (c === "accesorii-tapiterie") {
      accesoriiTapiterieIschecked = true;
    }

    if (c === "ornamente-mobila-lemn") {
      ornamenteMobilaLemnIschecked = true;
    }

    if (c === "menghine-tamplar") {
      menghineTamplarIschecked = true;
    }

    if (c === "adezivi-si-chituri-pentru-lemn") {
      adeziviSiChituriPentruLemnIschecked = true;
    }

    if (c === "solutii-pentru-tratarea-lemnului") {
      solutiiPentruTratareaLemnuluiIschecked = true;
    }

    if (c === "cuie-capse-si-agrafe") {
      cuieCapseSiAgrafeIschecked = true;
    }

    if (c === "abrazive-pentru-lemn") {
      abrazivePentruLemnIschecked = true;
    }

    if (c === "pistoale-pneumatice-si-vopsitorie") {
      pistoalePneumaticeSiVopsitorieIschecked = true;
    }

    if (c === "echipamente-de-protectie") {
      echipamenteDeProtectieIschecked = true;
    }

    return null;
  });

  if (available) {
    isAvailable = "true";
  } else {
    isAvailable = "false";
  }

  // Show modal form const
  const [showSuccess, setShowSuccess] = useState(false);
  const openShowSuccesHandler = () => setShowSuccess(true);

  //on take photo
  function handleTakePhoto(dataUri) {
    //adaugam base64 imaginile in array(state)
    setDataImg([...dataImg, dataUri]);

    //transformam fiecare base64 in File si adaugam in array (state)
    let name = "numeimg";
    const file = dataURLtoFile(dataUri, name);

    setFileData([...fileData, file]);
  }

  function swapCarouselCamera() {
    setIsCameraOn(true);
    setIsCarouselOff(true);
  }

  useEffect(() => {
    if (dataImg.length === 3) {
      setIsCameraOn(false);
    }
  }, [dataImg]);

  const reopenCamera = () => {
    //width: 509, height: 906
    setDataImg([]);
    setFileData([]);
    setIsCameraOn(true);
  };

  const handleOnClickSubcateg = (event) => {
    
    if (showSubcategEdit === "") {
        setShowSubcategEdit("unchecked");    
    /* 
        accesoriiMobilaIschecked = false;
        accesoriiTapiterieIschecked = false;
        ornamenteMobilaLemnIschecked = false;
        menghineTamplarIschecked = false;
        adeziviSiChituriPentruLemnIschecked = false;
        solutiiPentruTratareaLemnuluiIschecked = false;
        cuieCapseSiAgrafeIschecked = false;
        abrazivePentruLemnIschecked = false;
        pistoalePneumaticeSiVopsitorieIschecked = false;
        echipamenteDeProtectieIschecked = false; */
    } else {
     if(showSubcategEdit !== "unchecked"){
       if(showSubcategEdit === event.target.value){
        setShowSubcategEdit("unchecked");       
       }    
      
      }else{       
        setShowSubcategEdit(event.target.value);
      }
      
    }
  };

  return (
    <React.Fragment>
      <Modal
        show={showSuccess}
        // onCancel={closeShowSuccessHandler}
        header={"Modificare produs"}
        contentClass="menu-selected__modal-content"
        footerClass="menu-selected__modal-actions"
      >
        <p>Produs modificat cu succes!!!</p>
        <footer>
          <Button className="btn" onClick={history.go}>
            Ok
          </Button>
        </footer>
      </Modal>

      <ErrorModal error={error} onClear={errorHandler} />
      <CSSTransition
        classNames={{
          appear: "slide-in-left-enter",
          appearActive: "slide-in-left-enter-active",
        }}
        timeout={1000}
        in
        appear
        unmountOnExit
      >
        <Form className="menu-form" onSubmit={menuSubmitHandler}>
          {isLoading && <LoadingSpinner asOverlay />}
          {isCarouselOff && (
            <div>
              <p style={{ "text-align": "center" }}>
                Vizualizare imagini salvate:
              </p>
              <Carousel showThumbs={false} showStatus={false}>
                <div>
                  <img                    
                    src={`https://tecnifer.ro/backend/${props.items.imageMain}`}
                    alt={props.items.name + "_1"}
                  />
                </div>
                <div>
                  <img
                    src={`https://tecnifer.ro/backend/${props.items.image0}`}
                    alt={props.items.name + "_2"}
                  />
                </div>
                <div>
                  <img
                    src={`https://tecnifer.ro/backend/${props.items.image1}`}
                    alt={props.items.name + "_3"}
                  />
                </div>
              </Carousel>
            </div>
          )}
          {!isCarouselOff && (
            <div style={{ textAlign: "center" }}>
              <br />
              <Button onClick={swapCarouselCamera}>
                Sterge imaginile / Deschide Camera
              </Button>
            </div>
          )}
          <div className="form-group">
            {/*  <ImageUpload
              imageUrl={imageUrl}
              id="image"
              onInput={inputHandler}
            /> */}
            <div>
              <ImagePreview dataUri={dataImg} isFullscreen={false} />
              {isCarouselOff && isCameraOn ? (
                <div>
                  <p style={{ "text-align": "center" }}>Fotografiază din nou</p>
                  <Camera
                    className="menu-take-photo"
                    isImageMirror={false}
                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                    idealResolution={{ width: 509, height: 906 }}
                    imageType={IMAGE_TYPES.JPG}
                    onTakePhoto={(dataUri) => {
                      handleTakePhoto(dataUri);
                    }}
                  />
                </div>
              ) : (
                isCarouselOff && (
                  <div style={{ "text-align": "center" }}>
                    <Button onClick={reopenCamera}>Redeschide Camera</Button>
                  </div>
                )
              )}
            </div>
            <Input
              id="name"
              element="input"
              type="text"
              label="NUME"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Please enter menu name"
              onInput={inputHandler}
              initialValue={props.items.name}
              initialValid={formState.inputs.name.isValid}
            />
            <Input
              id="description"
              element="textarea"
              type="text"
              rows="8"
              label="DESCRIERE"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Please enter menu description"
              onInput={inputHandler}
              initialValue={props.items.description}
              initialValid={formState.inputs.description.isValid}
            />
            CATEGORY
            <fieldset>
              <div className="item">
                <label className="accesoriimobila" htmlFor="accesoriimobila">
                  Accesorii mobilă{" "}
                  <input
                    id="accesoriimobila"
                    className="category"
                    type="checkbox"
                    value="accesorii-mobila"
                    onClick={handleOnClickSubcateg}                    
                    defaultChecked={accesoriiMobilaIschecked}                    
                  />
                  {(showSubcategEdit === "" ? accesoriiMobilaIschecked : showSubcategEdit === "accesorii-mobila") && (
                    <div className="subcateg__container">
                      <fieldset>
                        <div className="item">
                          <label
                            className="manere-si-butoni"
                            htmlFor="manere-si-butoni"
                          >
                            Mânere și butoni{" "}
                            <input
                              id="manere-si-butoni"
                              className="subcategory"
                              type="checkbox"
                              value="manere-si-butoni"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "manere-si-butoni" : showSubcategEdit === "manere-si-butoni"}
                             
                            />
                          </label>
                          <label className="balamale" htmlFor="balamale">
                            Balamale{" "}
                            <input
                              id="balamale"
                              className="subcategory"
                              type="checkbox"
                              value="balamale"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "balamale" : showSubcategEdit === "balamale"}
                            />
                          </label>
                          <label className="blocatori" htmlFor="blocatori">
                            Blocatori{" "}
                            <input
                              id="blocatori"
                              className="subcategory"
                              type="checkbox"
                              value="blocatori"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "blocatori" : showSubcategEdit === "blocatori"}
                            />
                          </label>
                          <label className="broaste" htmlFor="broaste">
                            Broaște{" "}
                            <input
                              id="broaste"
                              className="subcategory"
                              type="checkbox"
                              value="broaste"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "broaste" : showSubcategEdit === "broaste"}
                            />
                          </label>
                          <label className="chei" htmlFor="chei">
                            Chei{" "}
                            <input
                              id="chei"
                              className="subcategory"
                              type="checkbox"
                              value="chei"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "chei" : showSubcategEdit === "chei"}
                            />
                          </label>
                          <label className="sild" htmlFor="sild">
                            Șild{" "}
                            <input
                              id="sild"
                              className="subcategory"
                              type="checkbox"
                              value="sild"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "sild" : showSubcategEdit === "sild"}
                            />
                          </label>
                          <label className="bucsa" htmlFor="bucsa">
                            Bucșa{" "}
                            <input
                              id="bucsa"
                              className="subcategory"
                              type="checkbox"
                              value="bucsa"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "bucsa" : showSubcategEdit === "bucsa"}
                            />
                          </label>
                          <label className="magnet" htmlFor="magnet">
                            Magnet{" "}
                            <input
                              id="magnet"
                              className="subcategory"
                              type="checkbox"
                              value="magnet"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "magnet" : showSubcategEdit === "magnet"}
                            />
                          </label>
                        </div>
                      </fieldset>
                    </div>
                  )}
                </label>
                <label
                  className="accesoriitapiterie"
                  htmlFor="accesoriitapiterie"
                >
                  Accesorii tapițerie{" "}
                  <input
                    id="accesoriitapiterie"
                    className="category"
                    type="checkbox"
                    value="accesorii-tapiterie"
                    onClick={handleOnClickSubcateg}
                    defaultChecked={accesoriiTapiterieIschecked}
                  />
                  {(showSubcategEdit === "" ? accesoriiTapiterieIschecked : showSubcategEdit === "accesorii-tapiterie") && (
                    <div className="subcateg__container">
                      <div className="item">
                        <fieldset>
                          <label
                            className="cuie-false-tapiterie-banda-bronz"
                            htmlFor="cuie-false-tapiterie-banda-bronz"
                          >
                            Cuie false tapițerie bandă bronz{" "}
                            <input
                              id="cuie-false-tapiterie-banda-bronz"
                              className="subcategory"
                              type="checkbox"
                              value="cuie-false-tapiterie-banda-bronz"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "cuie-false-tapiterie-banda-bronz" : showSubcategEdit === "cuie-false-tapiterie-banda-bronz"}
                            />
                          </label>
                          <label
                            className="cuie-false-tapiterie-banda-argint"
                            htmlFor="cuie-false-tapiterie-banda-argint"
                          >
                            Cuie false tapițerie bandă argint{" "}
                            <input
                              id="cuie-false-tapiterie-banda-argint"
                              className="subcategory"
                              type="checkbox"
                              value="cuie-false-tapiterie-banda-argint"
                              defaultChecked={props.items.subcategory[0] === "cuie-false-tapiterie-banda-argint"}
                            />
                          </label>
                          <label
                            className="cuie-false-tapiterie-banda-fumuriu"
                            htmlFor="cuie-false-tapiterie-banda-fumuriu"
                          >
                            Cuie false tapițerie bandă fumuriu{" "}
                            <input
                              id="cuie-false-tapiterie-banda-fumuriu"
                              className="subcategory"
                              type="checkbox"
                              value="cuie-false-tapiterie-banda-fumuriu"
                              defaultChecked={props.items.subcategory[0] === "cuie-false-tapiterie-banda-fumuriu"}
                            />
                          </label>
                          <label
                            className="cuie-false-tapiterie-banda-continua"
                            htmlFor="cuie-false-tapiterie-banda-continua"
                          >
                            Cuie false tapițerie bandă continuă{" "}
                            <input
                              id="cuie-false-tapiterie-banda-continua"
                              className="subcategory"
                              type="checkbox"
                              value="cuie-false-tapiterie-banda-continua"
                              defaultChecked={props.items.subcategory[0] === "cuie-false-tapiterie-banda-continua"}
                            />
                          </label>
                          <label
                            className="cuie-tapiterie-D24"
                            htmlFor="cuie-tapiterie-D24"
                          >
                            Cuie tapițerie D24{" "}
                            <input
                              id="cuie-tapiterie-D24"
                              className="subcategory"
                              type="checkbox"
                              value="cuie-tapiterie-D24"
                              defaultChecked={props.items.subcategory[0] === "cuie-tapiterie-D24"}
                            />
                          </label>
                          <label
                            className="cuie-tapiterie-D20"
                            htmlFor="cuie-tapiterie-D20"
                          >
                            Cuie tapițerie D20{" "}
                            <input
                              id="cuie-tapiterie-D20"
                              className="subcategory"
                              type="checkbox"
                              value="cuie-tapiterie-D20"
                              defaultChecked={props.items.subcategory[0] === "cuie-tapiterie-D20"}
                            />
                          </label>
                          <label
                            className="cuie-tapiterie-patrate"
                            htmlFor="cuie-tapiterie-patrate"
                          >
                            Cuie tapițerie pătrate{" "}
                            <input
                              id="cuie-tapiterie-patrate"
                              className="subcategory"
                              type="checkbox"
                              value="cuie-tapiterie-patrate"
                              defaultChecked={props.items.subcategory[0] === "cuie-tapiterie-patrate"}
                            />
                          </label>
                          <label
                            className="capse-U-tapiterie-80-04"
                            htmlFor="capse-U-tapiterie-80-04"
                          >
                            Capse U tapițerie 80-04{" "}
                            <input
                              id="capse-U-tapiterie-80-04"
                              className="subcategory"
                              type="checkbox"
                              value="capse-U-tapiterie-80-04"
                              defaultChecked={props.items.subcategory[0] === "capse-U-tapiterie-80-04"}
                            />
                          </label>
                          <label
                            className="capse-U-tapiterie-80-06"
                            htmlFor="capse-U-tapiterie-80-06"
                          >
                            Capse U tapițerie 80-06{" "}
                            <input
                              id="capse-U-tapiterie-80-06"
                              className="subcategory"
                              type="checkbox"
                              value="capse-U-tapiterie-80-06"
                              defaultChecked={props.items.subcategory[0] === "capse-U-tapiterie-80-06"}
                            />
                          </label>
                          <label
                            className="capse-U-tapiterie-80-08"
                            htmlFor="capse-U-tapiterie-80-08"
                          >
                            Capse U tapițerie 80-08{" "}
                            <input
                              id="capse-U-tapiterie-80-08"
                              className="subcategory"
                              type="checkbox"
                              value="capse-U-tapiterie-80-08"
                              defaultChecked={props.items.subcategory[0] === "capse-U-tapiterie-80-08"}
                            />
                          </label>
                          <label
                            className="capse-U-tapiterie-80-10"
                            htmlFor="capse-U-tapiterie-80-10"
                          >
                            Capse U tapițerie 80-10{" "}
                            <input
                              id="capse-U-tapiterie-80-10"
                              className="subcategory"
                              type="checkbox"
                              value="capse-U-tapiterie-80-10"
                              defaultChecked={props.items.subcategory[0] === "capse-U-tapiterie-80-10"}
                            />
                          </label>
                          <label
                            className="capse-U-tapiterie-80-12"
                            htmlFor="capse-U-tapiterie-80-12"
                          >
                            Capse U tapițerie 80-12{" "}
                            <input
                              id="capse-U-tapiterie-80-12"
                              className="subcategory"
                              type="checkbox"
                              value="capse-U-tapiterie-80-12"
                              defaultChecked={props.items.subcategory[0] === "capse-U-tapiterie-80-12"}
                            />
                          </label>
                          <label
                            className="capse-U-tapiterie-80-14"
                            htmlFor="capse-U-tapiterie-80-14"
                          >
                            Capse U tapițerie 80-14{" "}
                            <input
                              id="capse-U-tapiterie-80-14"
                              className="subcategory"
                              type="checkbox"
                              value="capse-U-tapiterie-80-14"
                              defaultChecked={props.items.subcategory[0] === "capse-U-tapiterie-80-14"}
                            />
                          </label>
                          <label
                            className="capse-U-tapiterie-80-16"
                            htmlFor="capse-U-tapiterie-80-16"
                          >
                            Capse U tapițerie 80-16{" "}
                            <input
                              id="capse-U-tapiterie-80-16"
                              className="subcategory"
                              type="checkbox"
                              value="capse-U-tapiterie-80-16"
                              defaultChecked={props.items.subcategory[0] === "capse-U-tapiterie-80-16"}
                            />
                          </label>
                          <label
                            className="capse-U-tapiterie-SJK-8"
                            htmlFor="capse-U-tapiterie-SJK-8"
                          >
                            Capse U tapițerie SJK 8{" "}
                            <input
                              id="capse-U-tapiterie-SJK-8"
                              className="subcategory"
                              type="checkbox"
                              value="capse-U-tapiterie-SJK-8"
                              defaultChecked={props.items.subcategory[0] === "capse-U-tapiterie-SJK-8"}
                            />
                          </label>
                          <label
                            className="pistol-capse-U-80-16"
                            htmlFor="pistol-capse-U-80-16"
                          >
                            Pistol Capse U 80-16{" "}
                            <input
                              id="pistol-capse-U-80-16"
                              className="subcategory"
                              type="checkbox"
                              value="pistol-capse-U-80-16"
                              defaultChecked={props.items.subcategory[0] === "pistol-capse-U-80-16"}
                            />
                          </label>
                        </fieldset>
                      </div>
                    </div>
                  )}
                </label>
                <label
                  className="ornamentemobilalemn"
                  htmlFor="ornamentemobilalemn"
                >
                  Ornamente mobila lemn{" "}
                  <input
                    id="ornamentemobilalemn"
                    className="category"
                    type="checkbox"
                    value="ornamente-mobila-lemn"
                    onClick={handleOnClickSubcateg}
                    defaultChecked={ornamenteMobilaLemnIschecked}
                  />
                  {(showSubcategEdit === "" ? ornamenteMobilaLemnIschecked : showSubcategEdit === "ornamente-mobila-lemn") && (
                    <div className="subcateg__container">
                      <div className="item">
                        <fieldset>
                          <label
                            className="ornamente-lemn-sculptat-bara"
                            htmlFor="ornamente-lemn-sculptat-bara"
                          >
                            Ornamente lemn sculptat bară{" "}
                            <input
                              id="ornamente-lemn-sculptat-bara"
                              className="subcategory"
                              type="checkbox"
                              value="ornamente-lemn-sculptat-bara"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "ornamente-lemn-sculptat-bara" : showSubcategEdit === "ornamente-lemn-sculptat-bara"}
                            />
                          </label>
                          <label
                            className="ornament-mdf-presat"
                            htmlFor="ornament-mdf-presat"
                          >
                            Ornament MDF presat{" "}
                            <input
                              id="ornament-mdf-presat"
                              className="subcategory"
                              type="checkbox"
                              value="ornament-mdf-presat"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "ornament-mdf-presat" : showSubcategEdit === "ornament-mdf-presat"}
                            />
                          </label>
                        </fieldset>
                      </div>
                    </div>
                  )}
                </label>
                <label className="menghinetamplar" htmlFor="menghinetamplar">
                  Menghine tâmplar{" "}
                  <input
                    id="menghinetamplar"
                    className="category"
                    type="checkbox"
                    value="menghine-tamplar"
                    onClick={handleOnClickSubcateg}
                    defaultChecked={menghineTamplarIschecked}                    
                  />   
                   {(showSubcategEdit === "" ? menghineTamplarIschecked : showSubcategEdit === "menghine-tamplar") && (
                    <div className="subcateg__container">
                      <div className="item">
                        <fieldset>
                          <label
                            className="menghine-350-mm"
                            htmlFor="menghine-350-mm"
                          >
                            Menghine 350 mm{" "}
                            <input
                              id="menghine-350-mm"
                              className="subcategory"
                              type="checkbox"
                              value="menghine-350-mm"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "menghine-350-mm" : showSubcategEdit === "menghine-350-mm"}
                            />
                          </label>
                          <label
                            className="menghine-400-mm"
                            htmlFor="menghine-400-mm"
                          >
                            Menghine 400 mm{" "}
                            <input
                              id="menghine-400-mm"
                              className="subcategory"
                              type="checkbox"
                              value="menghine-400-mm"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "menghine-400-mm" : showSubcategEdit === "menghine-400-mm"}
                            />
                          </label>
                          <label
                            className="menghine-500-mm"
                            htmlFor="menghine-500-mm"
                          >
                            Menghine 500 mm{" "}
                            <input
                              id="menghine-500-mm"
                              className="subcategory"
                              type="checkbox"
                              value="menghine-500-mm"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "menghine-500-mm" : showSubcategEdit === "menghine-500-mm"}
                            />
                          </label>
                          <label
                            className="menghine-600-mm"
                            htmlFor="menghine-600-mm"
                          >
                            Menghine 600 mm{" "}
                            <input
                              id="menghine-600-mm"
                              className="subcategory"
                              type="checkbox"
                              value="menghine-600-mm"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "menghine-600-mm" : showSubcategEdit === "menghine-600-mm"}
                            />
                          </label>
                          <label
                            className="menghine-700-mm"
                            htmlFor="menghine-700-mm"
                          >
                            Menghine 700 mm{" "}
                            <input
                              id="menghine-700-mm"
                              className="subcategory"
                              type="checkbox"
                              value="menghine-700-mm"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "menghine-700-mm" : showSubcategEdit === "menghine-700-mm"}
                            />
                          </label>
                          <label
                            className="menghine-800-mm"
                            htmlFor="menghine-800-mm"
                          >
                            Menghine 800 mm{" "}
                            <input
                              id="menghine-800-mm"
                              className="subcategory"
                              type="checkbox"
                              value="menghine-800-mm"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "menghine-800-mm" : showSubcategEdit === "menghine-800-mm"}
                            />
                          </label>
                          <label
                            className="menghine-1000-mm"
                            htmlFor="menghine-1000-mm"
                          >
                            Menghine 1000 mm{" "}
                            <input
                              id="menghine-1000-mm"
                              className="subcategory"
                              type="checkbox"
                              value="menghine-1000-mm"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "menghine-1000-mm" : showSubcategEdit === "menghine-1000-mm"}
                            />
                          </label>
                          <label
                            className="menghine-1200-mm"
                            htmlFor="menghine-1200-mm"
                          >
                            Menghine 1200 mm{" "}
                            <input
                              id="menghine-1200-mm"
                              className="subcategory"
                              type="checkbox"
                              value="menghine-1200-mm"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "menghine-1200-mm" : showSubcategEdit === "menghine-1200-mm"}
                            />
                          </label>
                        </fieldset>
                      </div>
                    </div>
                  )}               
                </label>
                <label className="adezivichituri" htmlFor="adezivichituri">
                  Adezivi și chituri pentru lemn{" "}
                  <input
                    id="adezivichituri"
                    className="category"
                    type="checkbox"
                    value="adezivi-si-chituri-pentru-lemn"
                    onClick={handleOnClickSubcateg}
                    defaultChecked={adeziviSiChituriPentruLemnIschecked}
                  />
                   {(showSubcategEdit === "" ? adeziviSiChituriPentruLemnIschecked : showSubcategEdit === "adezivi-si-chituri-pentru-lemn") && (
                    <div className="subcateg__container">
                      <div className="item">
                        <fieldset>
                          <label className="aracet-d3" htmlFor="aracet-d3">
                            Aracet D3{" "}
                            <input
                              id="aracet-d3"
                              className="subcategory"
                              type="checkbox"
                              value="aracet-d3"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "aracet-d3" : showSubcategEdit === "aracet-d3"}
                            />
                          </label>
                          <label className="aracet-d4" htmlFor="aracet-d4">
                            Aracet D4{" "}
                            <input
                              id="aracet-d4"
                              className="subcategory"
                              type="checkbox"
                              value="aracet-d4"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "aracet-d4" : showSubcategEdit === "aracet-d4"}
                            />
                          </label>
                          <label className="aracet-d2" htmlFor="aracet-d2">
                            Aracet D2{" "}
                            <input
                              id="aracet-d2"
                              className="subcategory"
                              type="checkbox"
                              value="aracet-d2"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "aracet-d2" : showSubcategEdit === "aracet-d2"}
                            />
                          </label>
                          <label
                            className="adeziv-urelit-la-cald-pentru-presa"
                            htmlFor="adeziv-urelit-la-cald-pentru-presa"
                          >
                            Adeziv URELIT la cald pentru presa{" "}
                            <input
                              id="adeziv-urelit-la-cald-pentru-presa"
                              className="subcategory"
                              type="checkbox"
                              value="adeziv-urelit-la-cald-pentru-presa"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "adeziv-urelit-la-cald-pentru-presa" : showSubcategEdit === "adeziv-urelit-la-cald-pentru-presa"}
                            />
                          </label>
                          <label
                            className="chit-colorat-pe-baza-de-apa"
                            htmlFor="chit-colorat-pe-baza-de-apa"
                          >
                            Chit colorat pe baza de apa{" "}
                            <input
                              id="chit-colorat-pe-baza-de-apa"
                              className="subcategory"
                              type="checkbox"
                              value="chit-colorat-pe-baza-de-apa"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "chit-colorat-pe-baza-de-apa" : showSubcategEdit === "chit-colorat-pe-baza-de-apa"}
                            />
                          </label>
                          <label
                            className="baton-ceara-corectoare"
                            htmlFor="baton-ceara-corectoare"
                          >
                            Baton ceara corectoare{" "}
                            <input
                              id="baton-ceara-corectoare"
                              className="subcategory"
                              type="checkbox"
                              value="baton-ceara-corectoare"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "baton-ceara-corectoare" : showSubcategEdit === "baton-ceara-corectoare"}
                            />
                          </label>
                          <label
                            className="clei-de-oase-granulat"
                            htmlFor="clei-de-oase-granulat"
                          >
                            Clei de oase granulat{" "}
                            <input
                              id="clei-de-oase-granulat"
                              className="subcategory"
                              type="checkbox"
                              value="clei-de-oase-granulat"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "clei-de-oase-granulat" : showSubcategEdit === "clei-de-oase-granulat"}
                            />
                          </label>
                          <label
                            className="dopuri-lemn-reparatii"
                            htmlFor="dopuri-lemn-reparatii"
                          >
                            Dopuri lemn reparatii{" "}
                            <input
                              id="dopuri-lemn-reparatii"
                              className="subcategory"
                              type="checkbox"
                              value="dopuri-lemn-reparatii"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "dopuri-lemn-reparatii" : showSubcategEdit === "dopuri-lemn-reparatii"}
                            />
                          </label>
                          <label
                            className="barcute-lemn-reparatii"
                            htmlFor="barcute-lemn-reparatii"
                          >
                            Barcute lemn reparatii{" "}
                            <input
                              id="barcute-lemn-reparatii"
                              className="subcategory"
                              type="checkbox"
                              value="barcute-lemn-reparatii"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "barcute-lemn-reparatii" : showSubcategEdit === "barcute-lemn-reparatii"}
                            />
                          </label>
                          <label className="lamele-lemn" htmlFor="lamele-lemn">
                            Lamele lemn{" "}
                            <input
                              id="lamele-lemn"
                              className="subcategory"
                              type="checkbox"
                              value="lamele-lemn"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "lamele-lemn" : showSubcategEdit === "lamele-lemn"}
                            />
                          </label>
                          <label
                            className="dibluri-lemn"
                            htmlFor="dibluri-lemn"
                          >
                            Dibluri lemn{" "}
                            <input
                              id="dibluri-lemn"
                              className="subcategory"
                              type="checkbox"
                              value="dibluri-lemn"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "dibluri-lemn" : showSubcategEdit === "dibluri-lemn"}
                            />
                          </label>
                        </fieldset>
                      </div>
                    </div>
                  )}
                </label>

                <label className="trataresuprafete" htmlFor="trataresuprafete">
                  Soluții tratare suprafețe{" "}
                  <input
                    id="trataresuprafete"
                    className="category"
                    type="checkbox"
                    value="solutii-pentru-tratarea-lemnului"
                    onClick={handleOnClickSubcateg}
                    defaultChecked={solutiiPentruTratareaLemnuluiIschecked}
                  />
                  {(showSubcategEdit === "" ? solutiiPentruTratareaLemnuluiIschecked : showSubcategEdit === "solutii-pentru-tratarea-lemnului") && (
                    <div className="subcateg__container">
                      <div className="item">
                        <fieldset>
                          <label
                            className="ceara-borma-wachs"
                            htmlFor="ceara-borma-wachs"
                          >
                            Ceara Borma Wachs{" "}
                            <input
                              id="ceara-borma-wachs"
                              className="subcategory"
                              type="checkbox"
                              value="ceara-borma-wachs"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "ceara-borma-wachs" : showSubcategEdit === "ceara-borma-wachs"}
                            />
                          </label>
                          <label
                            className="ulei-parchet"
                            htmlFor="ulei-parchet"
                          >
                            Ulei parchet{" "}
                            <input
                              id="ulei-parchet"
                              className="subcategory"
                              type="checkbox"
                              value="ulei-parchet"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "ulei-parchet" : showSubcategEdit === "ulei-parchet"}
                            />
                          </label>
                          <label
                            className="anticarie"
                            htmlFor="anticarie"
                          >
                            Anticarie{" "}
                            <input
                              id="anticarie"
                              className="subcategory"
                              type="checkbox"
                              value="anticarie"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "anticarie" : showSubcategEdit === "anticarie"}
                            />
                          </label>
                          <label
                            className="serlac-fulgi"
                            htmlFor="serlac-fulgi"
                          >
                            Șerlac fulgi{" "}
                            <input
                              id="serlac-fulgi"
                              className="subcategory"
                              type="checkbox"
                              value="serlac-fulgi"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "serlac-fulgi" : showSubcategEdit === "serlac-fulgi"}
                            />
                          </label>
                          <label
                            className="bait-colorat-lichid"
                            htmlFor="bait-colorat-lichid"
                          >
                            Bait colorat lichid{" "}
                            <input
                              id="bait-colorat-lichid"
                              className="subcategory"
                              type="checkbox"
                              value="bait-colorat-lichid"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "bait-colorat-lichid" : showSubcategEdit === "bait-colorat-lichid"}
                            />
                          </label>
                          <label
                            className="bait-colorat-granule"
                            htmlFor="bait-colorat-granule"
                          >
                            Bait colorat granule{" "}
                            <input
                              id="bait-colorat-granule"
                              className="subcategory"
                              type="checkbox"
                              value="bait-colorat-granule"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "bait-colorat-granule" : showSubcategEdit === "bait-colorat-granule"}
                            />
                          </label>
                          <label
                            className="diluant-nitro"
                            htmlFor="diluant-nitro"
                          >
                            Diluan Nitro{" "}
                            <input
                              id="diluant-nitro"
                              className="subcategory"
                              type="checkbox"
                              value="diluant-nitro"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "diluant-nitro" : showSubcategEdit === "diluant-nitro"}
                            />
                          </label>
                          <label
                            className="alcool-tehnic"
                            htmlFor="alcool-tehnic"
                          >
                            Alcool tehnic{" "}
                            <input
                              id="alcool-tehnic"
                              className="subcategory"
                              type="checkbox"
                              value="alcool-tehnic"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "alcool-tehnic" : showSubcategEdit === "alcool-tehnic"}
                            />
                          </label>
                          <label className="patina" htmlFor="patina">
                            Patina{" "}
                            <input
                              id="patina"
                              className="subcategory"
                              type="checkbox"
                              value="patina"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "patina" : showSubcategEdit === "patina"}
                            />
                          </label>
                          <label
                            className="carioca-corectare-cu-lac"
                            htmlFor="carioca-corectare-cu-lac"
                          >
                            Carioca corectare cu lac{" "}
                            <input
                              id="carioca-corectare-cu-lac"
                              className="subcategory"
                              type="checkbox"
                              value="carioca-corectare-cu-lac"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "carioca-corectare-cu-lac" : showSubcategEdit === "carioca-corectare-cu-lac"}
                            />
                          </label>
                        </fieldset>
                      </div>
                    </div>
                  )}
                </label>

                <label className="cuiecapseagrafe" htmlFor="cuiecapseagrafe">
                  Cuie capse și agrafe{" "}
                  <input
                    id="cuiecapseagrafe"
                    className="category"
                    type="checkbox"
                    value="cuie-capse-si-agrafe"
                    onClick={handleOnClickSubcateg}
                    defaultChecked={cuieCapseSiAgrafeIschecked}
                  />
                  {(showSubcategEdit === "" ? cuieCapseSiAgrafeIschecked : showSubcategEdit === "cuie-capse-si-agrafe") && (
                    <div className="subcateg__container">
                      <div className="item">
                        <fieldset>
                          <label
                            className="cuie-serie-np-12-cu-cap"
                            htmlFor="cuie-serie-np-12-cu-cap"
                          >
                            Cuie serie NP 12 cu cap{" "}
                            <input
                              id="cuie-serie-np-12-cu-cap"
                              className="subcategory"
                              type="checkbox"
                              value="cuie-serie-np-12-cu-cap"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "cuie-serie-np-12-cu-cap" : showSubcategEdit === "cuie-serie-np-12-cu-cap"}
                            />
                          </label>
                          <label
                            className="cuie-serie-ng-14-cu-cap"
                            htmlFor="cuie-serie-ng-14-cu-cap"
                          >
                            Cuie serie NG 14 cu cap{" "}
                            <input
                              id="cuie-serie-ng-14-cu-cap"
                              className="subcategory"
                              type="checkbox"
                              value="cuie-serie-ng-14-cu-cap"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "cuie-serie-ng-14-cu-cap" : showSubcategEdit === "cuie-serie-ng-14-cu-cap"}
                            />
                          </label>
                          <label
                            className="cuie-serie-mg-cu-cap"
                            htmlFor="cuie-serie-mg-cu-cap"
                          >
                            Cuie serie MG cu cap{" "}
                            <input
                              id="cuie-serie-mg-cu-cap"
                              className="subcategory"
                              type="checkbox"
                              value="cuie-serie-mg-cu-cap"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "cuie-serie-mg-cu-cap" : showSubcategEdit === "cuie-serie-mg-cu-cap"}
                            />
                          </label>
                          <label
                            className="cuie-serie-mg-fara-cap"
                            htmlFor="cuie-serie-mg-fara-cap"
                          >
                            Cuie serie MG fara cap{" "}
                            <input
                              id="cuie-serie-mg-fara-cap"
                              className="subcategory"
                              type="checkbox"
                              value="cuie-serie-mg-fara-cap"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "cuie-serie-mg-fara-cap" : showSubcategEdit === "cuie-serie-mg-fara-cap"}
                            />
                          </label>
                          <label
                            className="cuie-serie-j-fara-cap"
                            htmlFor="cuie-serie-j-fara-cap"
                          >
                            Cuie serie J fara cap{" "}
                            <input
                              id="cuie-serie-j-fara-cap"
                              className="subcategory"
                              type="checkbox"
                              value="cuie-serie-j-fara-cap"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "cuie-serie-j-fara-cap" : showSubcategEdit === "cuie-serie-j-fara-cap"}
                            />
                          </label>
                          <label
                            className="mini-cuie-0,6-mm-15"
                            htmlFor="mini-cuie-0,6-mm-15"
                          >
                            Mini cuie 0,6mm 15{" "}
                            <input
                              id="mini-cuie-0,6-mm-15"
                              className="subcategory"
                              type="checkbox"
                              value="mini-cuie-0,6-mm-15"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "mini-cuie-0,6-mm-15" : showSubcategEdit === "mini-cuie-0,6-mm-15"}
                            />
                          </label>
                          <label
                            className="mini-cuie-0,6-mm-18"
                            htmlFor="mini-cuie-0,6-mm-18"
                          >
                            Mini cuie 0,6mm 18{" "}
                            <input
                              id="mini-cuie-0,6-mm-18"
                              className="subcategory"
                              type="checkbox"
                              value="mini-cuie-0,6-mm-18"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "mini-cuie-0,6-mm-18" : showSubcategEdit === "mini-cuie-0,6-mm-18"}
                            />
                          </label>
                          <label
                            className="mini-cuie-0,6-mm-25"
                            htmlFor="mini-cuie-0,6-mm-25"
                          >
                            Mini cuie 0,6mm 25{" "}
                            <input
                              id="mini-cuie-0,6-mm-25"
                              className="subcategory"
                              type="checkbox"
                              value="mini-cuie-0,6-mm-25"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "mini-cuie-0,6-mm-25" : showSubcategEdit === "mini-cuie-0,6-mm-25"}
                            />
                          </label>
                          <label
                            className="mini-cuie-0,6-mm-30"
                            htmlFor="mini-cuie-0,6-mm-30"
                          >
                            Mini cuie 0,6mm 30{" "}
                            <input
                              id="mini-cuie-0,6-mm-30"
                              className="subcategory"
                              type="checkbox"
                              value="mini-cuie-0,6-mm-30"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "mini-cuie-0,6-mm-30" : showSubcategEdit === "mini-cuie-0,6-mm-30"}
                            />
                          </label>
                        </fieldset>
                      </div>
                    </div>
                  )}
                </label>

                <label className="abrazivelemn" htmlFor="abrazivelemn">
                  Abrazive lemn{" "}
                  <input
                    id="abrazivelemn"
                    className="category"
                    type="checkbox"
                    value="abrazive-pentru-lemn"
                    onClick={handleOnClickSubcateg}
                    defaultChecked={abrazivePentruLemnIschecked}
                  />
                  {(showSubcategEdit === "" ? abrazivePentruLemnIschecked : showSubcategEdit === "abrazive-pentru-lemn") && (
                    <div className="subcateg__container">
                      <div className="item">
                        <fieldset>
                          <label
                            className="pasla-abraziva-rola"
                            htmlFor="pasla-abraziva-rola"
                          >
                            Pasla abraziva rola{" "}
                            <input
                              id="pasla-abraziva-rola"
                              className="subcategory"
                              type="checkbox"
                              value="pasla-abraziva-rola"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "pasla-abraziva-rola" : showSubcategEdit === "pasla-abraziva-rola"}
                            />
                          </label>
                          <label
                            className="panza-abraziva-rola"
                            htmlFor="panza-abraziva-rola"
                          >
                            Panza abraziva rola{" "}
                            <input
                              id="panza-abraziva-rola"
                              className="subcategory"
                              type="checkbox"
                              value="panza-abraziva-rola"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "panza-abraziva-rola" : showSubcategEdit === "panza-abraziva-rola"}
                            />
                          </label>
                          <label
                            className="hartie-abraziva-rola"
                            htmlFor="hartie-abraziva-rola"
                          >
                            Hartie abraziva rola{" "}
                            <input
                              id="hartie-abraziva-rola"
                              className="subcategory"
                              type="checkbox"
                              value="hartie-abraziva-rola"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "hartie-abraziva-rola" : showSubcategEdit === "hartie-abraziva-rola"}
                            />
                          </label>
                          <label
                            className="discuri-abraziv-d150"
                            htmlFor="discuri-abraziv-d150"
                          >
                            Discuri abraziv D150{" "}
                            <input
                              id="discuri-abraziv-d150"
                              className="subcategory"
                              type="checkbox"
                              value="discuri-abraziv-d150"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "discuri-abraziv-d150" : showSubcategEdit === "discuri-abraziv-d150"}
                            />
                          </label>
                          <label
                            className="discuri-abraziv-d125"
                            htmlFor="discuri-abraziv-d125"
                          >
                            Discuri abraziv D125{" "}
                            <input
                              id="discuri-abraziv-d125"
                              className="subcategory"
                              type="checkbox"
                              value="discuri-abraziv-d125"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "discuri-abraziv-d125" : showSubcategEdit === "discuri-abraziv-d125"}
                            />
                          </label>
                          <label
                            className="lana-otelata"
                            htmlFor="lana-otelata"
                          >
                            Lână oțelată{" "}
                            <input
                              id="lana-otelata"
                              className="subcategory"
                              type="checkbox"
                              value="lana-otelata"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "lana-otelata" : showSubcategEdit === "lana-otelata"}
                            />
                          </label>
                          <label
                            className="banda-533x75"
                            htmlFor="banda-533x75"
                          >
                            Bandă 533x75{" "}
                            <input
                              id="banda-533x75"
                              className="subcategory"
                              type="checkbox"
                              value="banda-533x75"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "banda-533x75" : showSubcategEdit === "banda-533x75"}
                            />
                          </label>
                          <label
                            className="banda-457x75"
                            htmlFor="banda-457x75"
                          >
                            Bandă 457x75{" "}
                            <input
                              id="banda-457x75"
                              className="subcategory"
                              type="checkbox"
                              value="banda-457x75"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "banda-457x75" : showSubcategEdit === "banda-457x75"}
                            />
                          </label>
                          <label
                            className="perie-teflon-makita"
                            htmlFor="perie-teflon-makita"
                          >
                            Perie teflon Makita{" "}
                            <input
                              id="perie-teflon-makita"
                              className="subcategory"
                              type="checkbox"
                              value="perie-teflon-makita"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "perie-teflon-makita" : showSubcategEdit === "perie-teflon-makita"}
                            />
                          </label>
                          <label
                            className="perie-otel-makita"
                            htmlFor="perie-otel-makita"
                          >
                            Perie otel Makita{" "}
                            <input
                              id="perie-otel-makita"
                              className="subcategory"
                              type="checkbox"
                              value="perie-otel-makita"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "perie-otel-makita" : showSubcategEdit === "perie-otel-makita"}
                            />
                          </label>
                          <label
                            className="burete-abraziv-rola-ass"
                            htmlFor="burete-abraziv-rola-ass"
                          >
                            Burete abraziv rola ass{" "}
                            <input
                              id="burete-abraziv-rola-ass"
                              className="subcategory"
                              type="checkbox"
                              value="burete-abraziv-rola-ass"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "burete-abraziv-rola-ass" : showSubcategEdit === "burete-abraziv-rola-ass"}
                            />
                          </label>
                          <label
                            className="burete-abraziv-rola-rosu"
                            htmlFor="burete-abraziv-rola-rosu"
                          >
                            Burete abraziv rola rosu{" "}
                            <input
                              id="burete-abraziv-rola-rosu"
                              className="subcategory"
                              type="checkbox"
                              value="burete-abraziv-rola-rosu"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "burete-abraziv-rola-rosu" : showSubcategEdit === "burete-abraziv-rola-rosu"}
                            />
                          </label>                         
                          <label
                            className="burete-abraziv-4-laturi"
                            htmlFor="burete-abraziv-4-laturi"
                          >
                            Burete abraziv 4 laturi{" "}
                            <input
                              id="burete-abraziv-4-laturi"
                              className="subcategory"
                              type="checkbox"
                              value="burete-abraziv-4-laturi"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "burete-abraziv-4-laturi" : showSubcategEdit === "burete-abraziv-4-laturi"}
                            />
                          </label>
                          <label
                            className="burete-abraziv-2-laturi"
                            htmlFor="burete-abraziv-2-laturi"
                          >
                            Burete abraziv 2 laturi{" "}
                            <input
                              id="burete-abraziv-2-laturi"
                              className="subcategory"
                              type="checkbox"
                              value="burete-abraziv-2-laturi"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "burete-abraziv-2-laturi" : showSubcategEdit === "burete-abraziv-2-laturi"}
                            />
                          </label>
                        </fieldset>
                      </div>
                    </div>
                  )}
                </label>

                <label
                  className="pneumaticevopsitorie"
                  htmlFor="pneumaticevopsitorie"
                >
                  Pneumatice și vopsitorie{" "}
                  <input
                    id="pneumaticevopsitorie"
                    className="category"
                    type="checkbox"
                    value="pistoale-pneumatice-si-vopsitorie"
                    onClick={handleOnClickSubcateg}
                    defaultChecked={pistoalePneumaticeSiVopsitorieIschecked}
                  />
                  {(showSubcategEdit === "" ? pistoalePneumaticeSiVopsitorieIschecked : showSubcategEdit === "pistoale-pneumatice-si-vopsitorie") && (
                    <div className="subcateg__container">
                      <div className="item">
                        <fieldset>
                          <label
                            className="pistoale-pneumatice"
                            htmlFor="pistoale-pneumatice"
                          >
                            Pistoale pneumatice{" "}
                            <input
                              id="pistoale-pneumatice"
                              className="subcategory"
                              type="checkbox"
                              value="pistoale-pneumatice"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "pistoale-pneumatice" : showSubcategEdit === "pistoale-pneumatice"}
                            />
                          </label>
                          <label
                            className="pistol-de-vopsit-pentru-lacuri"
                            htmlFor="pistol-de-vopsit-pentru-lacuri"
                          >
                            Pistol de vopsit pentru lacuri{" "}
                            <input
                              id="pistol-de-vopsit-pentru-lacuri"
                              className="subcategory"
                              type="checkbox"
                              value="pistol-de-vopsit-pentru-lacuri"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "pistol-de-vopsit-pentru-lacuri" : showSubcategEdit === "pistol-de-vopsit-pentru-lacuri"}
                            />
                          </label>
                          <label
                            className="aerograf-cu-presiune-in-cupa"
                            htmlFor="aerograf-cu-presiune-in-cupa"
                          >
                            Aerograf cu presiune în cupă{" "}
                            <input
                              id="aerograf-cu-presiune-in-cupa"
                              className="subcategory"
                              type="checkbox"
                              value="aerograf-cu-presiune-in-cupa"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "aerograf-cu-presiune-in-cupa" : showSubcategEdit === "aerograf-cu-presiune-in-cupa"}
                            />
                          </label>
                        </fieldset>
                      </div>
                    </div>
                  )}
                </label>

                <label className="ecprotectie" htmlFor="ecprotectie">
                  Echipamente de protecție{" "}
                  <input
                    id="ecprotectie"
                    className="category"
                    type="checkbox"
                    value="echipamente-de-protectie"
                    onClick={handleOnClickSubcateg}
                    defaultChecked={echipamenteDeProtectieIschecked}
                  />
                  {(showSubcategEdit === "" ? echipamenteDeProtectieIschecked : showSubcategEdit === "echipamente-de-protectie") && (
                    <div className="subcateg__container">
                      <div className="item">
                        <fieldset>
                          <label
                            className="manusi"
                            htmlFor="manusi"
                          >
                            Manusi{" "}
                            <input
                              id="manusi"
                              className="subcategory"
                              type="checkbox"
                              value="manusi"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "manusi" : showSubcategEdit === "manusi"}
                            />
                          </label>
                          <label
                            className="masca-protectie"
                            htmlFor="masca-protectie"
                          >
                            Masca protectie{" "}
                            <input
                              id="masca-protectie"
                              className="subcategory"
                              type="checkbox"
                              value="masca-protectie"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "masca-protectie" : showSubcategEdit === "masca-protectie"}
                            />
                          </label>
                          <label
                            className="ochelari-protectie"
                            htmlFor="ochelari-protectie"
                          >
                            Ochelari protectie{" "}
                            <input
                              id="ochelari-protectie"
                              className="subcategory"
                              type="checkbox"
                              value="ochelari-protectie"
                              defaultChecked={showSubcategEdit === "" ? props.items.subcategory[0] === "ochelari-protectie" : showSubcategEdit === "ochelari-protectie"}
                            />
                          </label>
                        </fieldset>
                      </div>
                    </div>
                  )}
                </label>
              </div>
            </fieldset>
            <h4 className="available">IN STOC</h4>
            <select
              id="selectAvailable"
              className="select-available"
              defaultValue={isAvailable}
            >
              <option value="true">DA</option>

              <option value="false">NU</option>
            </select>
            <Input
              id="price"
              element="input"
              type="number"
              label="PRET CU TVA"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Introduceti pretul"
              onInput={inputHandler}
              initialValue={props.items.price}
              initialValid={formState.inputs.price.isValid}
            />
            {/* <Input
              id="priceOld"
              element="input"
              type="number"
              label="PRET VECHI"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Introduceti pretul vechi"
              onInput={inputHandler}
              initialValue={props.items.priceOld}
              initialValid={formState.inputs.priceOld.isValid}
            /> */}
            <Input
              id="color"
              element="input"
              type="text"
              label="CULOARE"
              validators={[]}
              errorText="Introduceti culoarea"
              onInput={inputHandler}
              initialValue={props.items.color}
              initialValid={formState.inputs.color.isValid}
            />
            <Input
              id="dimensions"
              element="input"
              type="text"
              label="DIMENSIUNI"
              validators={[]}
              errorText="Introduceti dimensiunea"
              onInput={inputHandler}
              initialValue={props.items.dimensions}
              initialValid={formState.inputs.dimensions.isValid}
            />             
            <Input
              id="brand"
              element="input"
              type="text"
              label="BRAND"
              validators={[]}
              errorText="Introduceti brandul"
              onInput={inputHandler}
              initialValue={props.items.brand}
              initialValid={formState.inputs.brand.isValid}
            />        
            <Input
              id="brand"
              element="input"
              type="text"
              label="MATERIAL"
              validators={[]}
              errorText="Introduceti materialul"
              onInput={inputHandler}
              initialValue={props.items.material}
              initialValid={formState.inputs.material.isValid}
            />
            <Input
              id="quantity"
              element="input"
              type="text"
              label="CANTITATE"
              validators={[]}
              errorText="Introduceti cantitatea"
              onInput={inputHandler}
              initialValue={props.items.quantity}
              initialValid={formState.inputs.quantity.isValid}
            />
          </div>

          <div className="update-menu__actions">
            <Button id="btnSubmit" type="submit" disabled={!formState.isValid}>
              SALVEAZA
            </Button>

            {/* <Button id="btnPick" type="button" to={"/admin/managemenus"}>
              PICTURE
            </Button> */}

            <Button id="btnCancel" type="button" to={"/admin/managemenus"}>
              ANULEAZA
            </Button>
          </div>
        </Form>
      </CSSTransition>
    </React.Fragment>
  );
};

export default EditMenu;
