import React,{useState} from "react";

import MenuItem from "./MenuItem";

import { CSSTransition } from "react-transition-group";

import Table from "../../shared/components/UIElements/Table";

import Button from "../../shared/components/FormElements/Button";

import "./MenuList.css";

const MenuList = props => {

  const[serachItem, setSearchItem] = useState("");
  
  const filteredItems = props.items.filter(item => item.name.trim().toLowerCase().includes(serachItem.trim() !== "" ? serachItem.trim().toString().toLowerCase() : null));
  
  const handleChangeSearchItem = (event) => {
    setSearchItem(event.target.value);
  }

  if (props.items.length === 0) {
    return (
      <React.Fragment>
        <div className="addmenu-container">
          <Button to={"/admin/managemenus/addproduct"} className="btn-add">
            Adauga un produs
          </Button>
        </div>

        <div className="center">
          <h2>Niciun produs</h2>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <CSSTransition
        classNames={{
          appear: "slide-in-left-enter",
          appearActive: "slide-in-left-enter-active"
        }}
        timeout={1000}
        in
        appear
        unmountOnExit
      >
        <div className="addmenu-container">
        <h3>ADAUGARE PRODUS</h3>
          <Button to={"/admin/managemenus/addproduct"} className="btn-add">
            ADAUGA PRODUS NOU
          </Button>
          <br/>
          <hr/>
          <h3>MODIFICARE PRODUS</h3>
          <label style={{fontSize:'20px'}}>Caută dupa numele unui produs</label>
          <input style={{fontSize:'24px'}} type="text" value={serachItem} onChange={handleChangeSearchItem} />

          <Table>
            <tbody>
              {filteredItems.map(menu => (
                <tr key={menu._id}>
                  <MenuItem
                    createdAt={menu.createdAt}
                    name={menu.name}
                    category={[menu.category].join(",")}
                    subcategory={[menu.subcategory].join(",")}
                    price={menu.price}
                    priceOld={menu.priceOld}
                    color={menu.color}
                    dimensions={menu.dimensions}
                    brand={menu.brand}
                    material={menu.material}
                    quantity={menu.quantity}
                    description={menu.description}
                  />

                  <td className="btn-actions">
                    <Button
                      className="btn-edit"
                      to={`/admin/managemenus/${menu._id}`}
                    >
                      MODIFICA
                    </Button>

                    <Button
                      className="btn-delete"
                      to={`/admin/managemenus/delete/${menu._id}`}
                    >
                      STERGE
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>          
        </div>
      </CSSTransition>
    </React.Fragment>
  );
};

export default MenuList;
