import React, { useState, useEffect, useContext } from "react";

import CartItem from "../component/CartItem";

import { AuthContext } from "../../shared/components/Context/auth-context";

import { CartContext } from "../../shared/components/Context/cart-context";

import ErrorModal from "../../shared/components/UIElements/ErrorModal";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import Button from "../../shared/components/FormElements/Button";

import Card from "../../shared/components/UIElements/Card";

import Modal from "../../shared/components/UIElements/Modal";

import { Helmet } from "react-helmet";

const Cart = (props) => {
  const [totalPrice, setTotalPrice] = useState(0);

  const [taxPrice, setTaxPrice] = useState(0);

  const [subTotalPrice, setSubTotalPrice] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState();

  const [loadedItems, setLoadedItems] = useState([]);

  const [orderedCartFlag, setOrderedCartFlag] = useState();

  const [changedItemQuantityFlag, setChangedItemQuantityFlag] = useState();

  const [deletedItemFlag, setDeletedItemFlag] = useState();

  const [nrTel, setNrTel] = useState("");

  const [nrTelDb, setNrTelDb] = useState("");

  const auth = useContext(AuthContext);

  const cartContext = useContext(CartContext);

  cartContext.saveCartItems(loadedItems);

  useEffect(() => {
    setIsLoading(true);

    let responseData;

    const sendRequest = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_DOMAIN}/v1/cart/${auth.userId !== false ? auth.userId : auth.userIDD}`
        );

        responseData = await response.json();

        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }

        setLoadedItems(responseData.data);
      } catch (err) {
        setError(err.message);
      }

      setIsLoading(false);

      let itemTotal = 0;
      let itemsTotal = 0;

      if (responseData) {
        responseData.data.map((item, i) => {
          itemTotal = item.quantity * item.price;
          itemsTotal = itemsTotal + itemTotal;
          return 0;
        });
      }

      setTotalPrice((itemsTotal - itemsTotal * 0.2).toFixed(2));

      setTaxPrice((itemsTotal * 0.19).toFixed(2));

      setSubTotalPrice(itemsTotal.toFixed(2));
    };

    if (auth.userId !== null) {
      sendRequest();

      setChangedItemQuantityFlag(false);

      setOrderedCartFlag(false);

      setDeletedItemFlag(false);
    }
    else{
      setIsLoading(false);
    }
  }, [auth.userId,auth.userIDD, changedItemQuantityFlag, orderedCartFlag, deletedItemFlag]);

  useEffect(() => {
    const sendRequest = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_DOMAIN}/v1/cart/usertel/${auth.userId}`
        );

        const responseData = await response.json();

        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }

        setNrTelDb(responseData.data.phone);
      } catch (err) {
        setError(err.message);
      }

      setIsLoading(false);
    };
    if (auth.userId !== null && auth.userId !== false) {
      sendRequest();
    }else{
      setIsLoading(false);
    }
  }, [auth.userId]);

  const errorHandler = () => {
    setError(null);
  };

  const changeItemQuantity = (itemCart, operation) => {
    const sendRequest = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_DOMAIN}/v1/cart/${itemCart.menuId}/userid/${itemCart.userId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },

            body: JSON.stringify({
              menuId: itemCart.menuId,

              name: itemCart.name,

              quantity:
                operation === "inc"
                  ? parseInt(itemCart.quantity) + 1
                  : parseInt(itemCart.quantity) - 1,

              price: itemCart.price,
            }),
          }
        );

        const responseData = await response.json();

        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }

        setChangedItemQuantityFlag(true);
      } catch (err) {
        console.log(err);
      }
    };

    sendRequest();

    if (operation === "inc") {
      cartContext.saveCountItemsCart(cartContext.countItemsCart + 1);
    } else {
      cartContext.saveCountItemsCart(cartContext.countItemsCart - 1);
    }
  };

  const removeFromCart = (menuid) => {
    const sendRequest = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_DOMAIN}/v1/cart/${menuid}/userid/${auth.userId !== false ? auth.userId : auth.userIDD}`
        );

        const responseData = await response.json();

        cartContext.saveCountItemsCart(
          cartContext.countItemsCart - responseData.data[0].quantity
        );

        cartContext.saveCartItems(
          cartContext.cartItems.filter(
            (item) => item._id === responseData.data[0]._id
          )
        );

        // Throw error

        if (!response.ok) {
          throw new Error(responseData.error);
        }
      } catch (err) {
        console.log(err);
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_DOMAIN}/v1/cart/${menuid}/userid/${auth.userId !== false ? auth.userId : auth.userIDD}`,
          {
            method: "DELETE",
          }
        );

        const responseData = await response.json();

        setLoadedItems(responseData.data);

        // Throw error

        if (!response.ok) {
          throw new Error(responseData.error);
        }

        setDeletedItemFlag(true);
      } catch (err) {
        console.log(err);
      }
    };

    sendRequest();
  };

  const orderNow = () => {
    setIsLoading(true);

    const sendRequest = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_DOMAIN}/v1/order/ordernow`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },

            body: JSON.stringify({
              userid: auth.userId !== false ? auth.userId : auth.userIDD,
              userTel: nrTel !== "" ? nrTel : nrTelDb,
            }),
          }
        );

        const responseData = await response.json();

        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }

        cartContext.saveCountItemsCart(0);

        setOrderedCartFlag(true);
      } catch (err) {
        setError(err.message);
      }

      setIsLoading(false);
    };

    sendRequest();
    openShowOrderSucess();
  };
  const [showOrderSuccess, setShowOrderSucess] = useState(false);
  const openShowOrderSucess = () => setShowOrderSucess(true);

  const goToHome = () => {
    window.location.href = "https://tecnifer.ro/";
  };

  const handleChangeTel = (event) => {
    setNrTel(event.target.value);
  };
   
  return (
    <React.Fragment>
      <Modal
        show={showOrderSuccess}
        header={"Success"}
        contentClass=""
        footerClass=""
      >
        <p style={{ fontSize: "22px" }}>Comanda plasata cu success!</p>
        <p style={{ fontSize: "22px" }}>
          Veți fi contactat telefonic pentru confirmarea comenzii!
        </p>

        <footer>
          <Button className="btn" onClick={goToHome}>
            Ok
          </Button>
        </footer>
      </Modal>
      <Helmet>
        <title>Coșul meu</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div>
        <ErrorModal error={error} onClear={errorHandler} />

        {isLoading && (
          <div className="center">
            <LoadingSpinner />
          </div>
        )}

        {!isLoading && loadedItems.length > 0 && (
          <div>
            <ul className="order-list">
              {loadedItems.map((itemCart, i) => (
                <CartItem
                  key={i}
                  itemCart={itemCart}
                  totalPrice={totalPrice}
                  taxPrice={taxPrice}
                  subTotalPrice={subTotalPrice}
                  removeFromCart={removeFromCart}
                  changeItemQuantityFromChild={changeItemQuantity}
                />
              ))}
            </ul>

            <div className="cart-footer">
              <div className="cart-total">
                <h4>TOTAL FARĂ TVA:</h4>
                <h4>{totalPrice} RON</h4>
              </div>

              <div className="cart-tax">
                <h4>VALOARE TVA:</h4>
                <h4>{taxPrice} RON</h4>
              </div>

              <div className="cart-sub-total">
                <h4>TOTAL:</h4>
                <h4>{subTotalPrice} RON</h4>
              </div>
              {!auth.isLoggedIn && (
                <div className="cart__container--nrtel">
                  <label className="cart__label--nrtel">
                    Introduceti numărul de telefon
                    <br /> pentru confirmarea comenzii
                  </label>
                  <input
                    className="cart__input--nrtel"
                    type="number"
                    name="nrTel"
                    value={nrTel}
                    onChange={handleChangeTel}
                  />
                </div>
              )}

              {/* <div className="cart-order-text">
                După trimiterea comenzii veți fi contactat telefonic
              </div> */}

              <div className="cart-button">
                {!auth.isLoggedIn && (
                  <Button
                    disabled={
                      !(
                        loadedItems.length > 0 &&
                        nrTel.length >= 10 &&
                        nrTel.length <= 12
                      )
                    }
                    onClick={orderNow}
                  >
                    TRIMITE COMANDA
                  </Button>
                )}
                {auth.isLoggedIn && (
                  <Button disabled={!loadedItems.length} onClick={orderNow}>
                    TRIMITE COMANDA
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}

        {loadedItems.length === 0 && (
          <Card style={{ margin: "20px", textAlign: "center" }}>
            Coșul este gol
          </Card>
        )}
      </div>
    </React.Fragment>
  );
};

export default Cart;
